import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faUser } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('');

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    setActiveLink(currentPath);
  }, []);

  const Ledcode = sessionStorage.getItem('Ledcode');
  // console.log("Ledcode:", Ledcode);
  
  if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {     
    // Return back from the page or perform any desired action
    window.history.back();
  }

  const customerType = sessionStorage.getItem('CustomerType');

   
  return (
    <nav className="navbar">          
      <Link
        to="/Home"
        className={activeLink === '/Home' ? 'active-link' : ''}
        onClick={() => handleLinkClick('/Home')}
      >
        <FontAwesomeIcon icon={faHome} className="navbar-icon" />
      </Link>
      {customerType !== 'AGENT' && (           
      <Link    
        to="/Cart"
        className={activeLink === '/Cart' ? 'active-link' : ''}
        onClick={() => handleLinkClick('/Cart')}
      >
        <FontAwesomeIcon icon={faShoppingCart} className="navbar-icon" />
      </Link>
      )}
      <Link
        to="/Notification"
        className={activeLink === '/Notification' ? 'active-link' : ''}
        onClick={() => handleLinkClick('/Notification')}
      >
        <FontAwesomeIcon icon={faUser} className="navbar-icon" />  
      </Link>
    </nav>
  );
};

export default Navbar;
