import React, { useState } from "react";
import "./Signup.css";
import { useNavigate} from "react-router-dom"; 

function Signup() {
  const [name, setName] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [add3, setAdd3] = useState("");
  const [add4, setAdd4] = useState("");
  const [mobile, setMobile] = useState("");
  const [taxno, setTaxno] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [agentCode, setCode] = useState("");
  
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  

  const navigate = useNavigate();

 const handleSubmit = (event) => {
    event.preventDefault();

    // Convert the shop name to uppercase
  const uppercaseName = name.toUpperCase();
  
    // Validation checks
    // if (email && !validateEmail(email)) {
    //   alert("Please enter a valid email address.");
    //   return;
    // }

    if (!validateMobileNumber(mobile)) {
      alert("Please enter a 10-digit mobile number.");
      return;
    }

    if (!validatePinCode(pinCode)) {
      alert("Please enter a 6-digit pin code.");
      return;
    }


    // Create an object with the form data
    const formData = {
      name: uppercaseName,
      add1,
      add2,
      add3, 
      add4,
      mobile,
      taxno,
      pinCode, 
      username,
      password,
      agentCode,
    };
  
    // Wrap the form data object in an array
    const requestData = [formData];
  
    console.log("Request Body:", JSON.stringify(requestData));
  
    // Make the signup API call
    fetch(`${apiBaseUrl}/user/SignUp/JAPAN_ACCESORIES`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the signup API
        console.log(data);
        // Reset the form fields
        setName("");
        setAdd1("");
        setAdd2("");
        setAdd3("");
        setAdd4("");
        setMobile("");
        setTaxno("");
        setPinCode("");
        setUsername("");
        setPassword("");
        setCode("");

       // Show a success message to the user
        if (data === 1) {
          alert("Form submitted successfully!");

          // console.log("username:",username);
          // console.log("password:",password);
          
           
          // Construct the login URL with the username and password as query parameters
          const loginURL = `${apiBaseUrl}/user/SignIn/JAPAN_ACCESORIES?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
  
          // Prepare the login request body including the Ledname
          const loginData = {
            Ledname: name,
          };
  
          // Make the login API call
          fetch(loginURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(loginData),
          })
            .then((response) => response.json())
            .then((loginResponse) => {
              // Handle the response from the login API
              console.log(loginResponse);
              // Show a success message to the user
              if (loginResponse.success) {
                alert("Login successful!");
                // Redirect the user to the dashboard or home page      
                navigate("/");
               
              } else {
                alert("Login failed.");
              }
                     
            })
            
            .catch((error) => {
              // Handle any errors
              console.error("Error:", error);
            //   // Show an error message to the user
            //   alert(
            //     "An error occurred while logging in. Please try again later."
            //   );
            });   

            // console.log("username1:",username);
            // console.log("password1:",password);
            
           // In the component where you navigate to the Otp component
         navigate("/Otp", { state: { username, password } });

        } else if (data === 0) {
          alert("Already registered.");
        } else {
          alert("Registration failed.");
        }
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
        // Show an error message to the user
        alert(
          "An error occurred while submitting the form. Please try again later."   
        );
      });
  };
  
  // const validateEmail = (email) => {
  //   // Regular expression for email validation
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const validateMobileNumber = (mobileNumber) => {
    // Regular expression for 10-digit number validation                   
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  const validatePinCode = (pinCode) => {
    // Regular expression for 6-digit number validation
    const pinCodeRegex = /^\d{6}$/;
    return pinCodeRegex.test(pinCode);
  };

  
return (

  <div className="main-signup">
    <div id="sign-up" className="signup-container">
        <div className="logo-container1">
      <img src="https://i.postimg.cc/hv4D6sqY/ALIK-SIGNUP-PAGE-0003-ALIK-LOGO.png" alt="Logo1" className="logo1" />
      <b className="sign-heading">Sign Up</b>
      <h3 className="sign-heading1">Get access to your orders</h3>
    <img src="https://i.postimg.cc/MGYYqZQF/ALIK-SIGNUP-PAGE-0002-Sign-up-cuate.png" alt="Slide 1" className="image1" />
     </div>
            <div className="signup-form">
        <form onSubmit={handleSubmit}  className="form-submit">
          <div className="form-row">

            <div className="form-column">
              <div className="input-container" id="but1">
              {/* <label id="but2">Name<span className="required"> *</span></label>   */}
             <input id="but3" placeholder="Shop Name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
              </div>
              <div className="input-container" id="but1">
                {/* <label id="but2">Address 1</label> */}
                <input placeholder="Address 1" type="text" value={add1} onChange={(e) => setAdd1(e.target.value)}  />
              </div>
              <div className="input-container" id="but1">
                {/* <label id="but2">Address 2</label> */}   
                <input  placeholder="Address 2" type="text" value={add2} onChange={(e) => setAdd2(e.target.value)}  />
              </div>
              <div className="input-container" id="but1">
                {/* <label id="but2">Address 3</label> */}
                <input  type="text" placeholder="Address 3" value={add3} onChange={(e) => setAdd3(e.target.value)}  />
              </div>
              <div className="input-container" id="but1">                                                                                                              
                {/* <label id="but2">contact person</label> */}
                <input id="but3" type="text" placeholder="Contact person"  value={add4} onChange={(e) => setAdd4(e.target.value)}  required/>
              </div>
             
            </div>
            <div className="form-column">
            <div className="input-container" id="but1">
                {/* <label id="but2">Mobile Number<span className="required"> *</span></label> */}
                <input id="but3" type="text" placeholder="Mobile Number" value={mobile} onChange={(e) => setMobile(e.target.value)} required />
              </div>
              <div className="input-container" id="but1">
                {/* <label id="but2">Email</label> */}
                <input id="but3"  type="text" placeholder="GST Number" value={taxno} onChange={(e) => setTaxno(e.target.value)}  />
              </div>
              <div className="input-container" id="but1">
              {/* <label id="but2">Pincode<span className="required"> *</span></label>                */}
               <input id="but3" type="text" placeholder="Pincode" value={pinCode} onChange={(e) => setPinCode(e.target.value)} required  />
              </div>
              <div className="input-container" id="but1">
              {/* <label id="but2">Username<span className="required"> *</span></label>                 */}
              <input id="but3" type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
              </div>
              <div className="input-container" id="but1">
              {/* <label id="but2">Password<span className="required"> *</span></label>                 */}
              <input id="but3" type="text" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
              </div>
              <div className="input-container" id="but1" style={{marginLeft:'-140px'}}>
                {/* <label id="but2">Address 3</label> */}
                <input  type="text" placeholder="Agent Code/Dealer Code" value={agentCode} onChange={(e) => setCode(e.target.value)}  />
              </div>
              </div>
           </div>
            <div className="button-container1">
            <input type="submit" value="Signup" />
            </div>
        </form>
      </div>
    </div>
    </div>
  );
}

export default Signup;
