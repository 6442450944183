import React, { useState } from 'react';
import './Otp.css';
import { useNavigate, useLocation, } from "react-router-dom";


function Otp(props) {
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const location = useLocation();
  const { username, password } = location.state || {};
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  
  const navigate = useNavigate();

  // console.log("username2:",username);                          
  // console.log("password2:",password);

 const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };
  
  const handleOtpVerification = () => {
    // Perform OTP verification logic here
    // console.log('Verifying OTP:', otp);
  
    const apiUrl = `${apiBaseUrl}/user/Verify/JAPAN_ACCESORIES`;
    const url = `${apiUrl}?username=${username}&password=${password}&otp=${otp}`;
  
    fetch(url)
      .then(response => response.json())
      .then(data => {
        // Handle the response from the API
        // console.log('API response:', data);
  
        if (data.message && data.message[0] && data.message[0][''] === 'Verification Success') {
          // Verification success, navigate to home page and pass data
          // console.log('Verification successful');
          alert("Verification successful");
        
          // Navigate to the Home page
          navigate('/');
        } else {
          // Handle verification failure
          // console.log('Verification failed');
          alert('OTP verification failed. Please try again.'); // Display an alert message
        }
        
      })
      .catch(error => {
        // Handle errors
        console.error('Error:', error);
      });
  };
  
 const handleOtpSend = () => {
    // Perform OTP sending logic here
    // console.log('Sending OTP to:', mobileNumber);
    
    setOtpSent(true);
  };


  return (
    <div className="page-wrapper3">
    <div className="otp-container">
      <img src="https://i.postimg.cc/fyjh90tB/otp-3.jpg" alt="Logo" className="logo5" />
      <h2 id='verify'>OTP Verification</h2>
      <h4>Please Contact <span  style={{color:'red'}}>+91 88484 83211</span> for OTP</h4>
        <div className="otp-input">
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={handleOtpChange}
          />
          <button onClick={handleOtpVerification}>Verify</button>
        </div>
      
       </div>
    </div>
 );
}

export default Otp;
