import React from 'react';
import { createRoot } from 'react-dom';
import './index.css';
import App from './App';


window.addEventListener('load', () => {
  const rootElement = document.getElementById('root');
  createRoot(rootElement).render(<App />);
  
});
