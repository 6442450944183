import React, { useState, useRef, useEffect } from 'react';
import './Reviewlist.css';
import 'jspdf-autotable';
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Reviewlist = (props) => {
  const items = JSON.parse(localStorage.getItem('selectedProducts')) || [];
  const [cartItems, setCartItems] = useState(items);
  const componentRef = useRef(null);
  const mobile = useRef(null)
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


  // useEffect(() => {
  //   // Push a new state to the history stack when the component mounts
  //   window.history.pushState(null, null, window.location.pathname);

  //   // Add a listener to the 'popstate' event
  //   window.addEventListener('popstate', handlePopstate);

  //   // Clean up the listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate);
  //   };
  // }, []);

  // const handlePopstate = () => {
  //   // Push another state to the history stack, effectively preventing the user from going back
  //   window.history.pushState(null, null, window.location.pathname);
  // };

  const customerType = sessionStorage.getItem('CustomerType');
  // console.log("type:", customerType);

  const handleDelete = (ID) => {
    const newCartItems = cartItems.filter(item => item.ID !== ID);
    localStorage.setItem('selectedProducts', JSON.stringify(newCartItems));
    setCartItems(newCartItems);
  };

  const handleDeleteAll = () => {
    localStorage.removeItem('selectedProducts');
    setCartItems([]);
  };

  const handleClearTable = (event) => {
    event.preventDefault();
    handleDeleteAll();
  };

  const Ledcode = sessionStorage.getItem('Ledcode');
  // console.log("Ledcode:", Ledcode);

  if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {
    // Return back from the page or perform any desired action
    window.history.back();
  }

  const LedName = sessionStorage.getItem("LedName");
  // console.log("ledname:", LedName);


  const handlePrint = async () => {

    if (Ledcode === '0') {
      alert("Please login first.");

      return;
    }

    mobile.current = "";

    const storedData = JSON.parse(localStorage.getItem('selectedProducts'));

    let addedSum = 0;

    var itemsArray = `[`;


    for (let i = 0; i < storedData.length; i++) {

      const items = storedData[i];
      // console.log("length:", storedData.length);
      // console.log("i:", i);


      const mop = storedData[i].MOP;
      // console.log("MOP:", mop);

      let dp = storedData[i].DP;
      // console.log("DP:", dp);

      

      let ap = storedData[i].AP;
      // console.log("AP:", ap);
      // if (Ledcode === '0') {
      //   delete storedData[i].DP; // Remove the DP property
      //   dp = mop; // Assign the value of MOP to DP
      // }

      let Branch = storedData[i].Branch
      console.log("BRANCH:", Branch);

      let RealPrateTax = storedData[i].RealPrateTax
      console.log("RealPrateTax:",RealPrateTax);

      if (customerType === 'CUSTOMER' || customerType === 'Customer') {
        // If the customer type is "CUSTOMER," delete the current DP value
        delete items.DP;
        // Store the MOP value in DP
        dp = mop;
        // Update the DP value in the storedData array
        storedData[i].DP = dp;
      }

      if (customerType === "AGENT") {
        delete items.DP;

        dp = ap;

        storedData[i].DP = dp;
      }
      if (customerType === "JS_EMPLOYEE") {
        delete items.DP;

        dp = Branch;

        storedData[i].DP = dp;
      }

      if (customerType === "TK_EMPLOYEE") {
        delete items.DP;

        dp = Branch;

        storedData[i].DP = dp;
      }

      if (customerType === "JS_MANAGER") {
        delete items.DP;

        dp = RealPrateTax;

        storedData[i].DP = dp;
      }

      if (customerType === "TK_MANAGER") {
        delete items.DP;

        dp = RealPrateTax;

        storedData[i].DP = dp;
      }

      const id = storedData[i].ID;
      // console.log("ID:", id);

      const quantity = storedData[i].quantity.undefined;
      // console.log("Quantity:", quantity);

      const mrp = quantity * dp;
      // console.log("MRP:", mrp);

      addedSum += mrp;

      const name = storedData[i].name;
      console.log("name:", name);

      const model = storedData[i].selectedValue;
      console.log("model:", model);

      // Create an array with a single object representing the item
      const item = `{\\"id\\":${id},\\"name\\":\\"${name}\\",\\"model\\":\\"${model}\\",\\"dp\\":${dp},\\"mrp\\":${mrp},\\"quantity\\":${quantity}}`;
      itemsArray += item
      if (i < storedData.length - 1) {
        itemsArray += `,`
      }
    }

    itemsArray += `]`
    console.log("itemsArray:", itemsArray);
    // Get the current date in the required format (DD-MM-YYYY)
    const currentDate = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");

    // Create an object with the required keys and values
    const order = `{"date":"${currentDate}","id":"${Ledcode}","contact":"${mobile.current}","items":"${itemsArray}"}`;
    console.log("order:", order);
    // Make a POST request to the API with the object as the payload
    try {
      const response = await fetch(`${apiBaseUrl}/orderAdd/JAPAN_ACCESORIES`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: order
      });

      console.log("Response status:", response.status);
      console.log("Response headers:", response.headers);


      // console.log("why:",id);
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0 && data[0].message === "success") {
        // console.log(`Item ${i + 1}: success`);
      }
      // else {
      //   console.log(`Item ${i + 1}: order failed`);
      // }
    } catch (error) {
      console.error(error);
    }

    // console.log("addedSum:", addedSum);

    const table = componentRef.current;
    // Construct the initial part of the tableText including LedName

    // Get the current date in the required format (DD-MM-YYYY)
    const cDate = new Date().toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });

    // Construct the initial part of the tableText including LedName and today's date
    let tableText = `Date: ${cDate}\nCustomer Name: ${LedName}\n\nCheckout my cart items:\n\n`;

    // Set custom headers
    const headers = ["Name", "Quantity", "Price"];

    // Calculate the maximum length of each column header
    // const maxHeaderLength = Math.max(...headers.map(header => header.length));

    // Determine the maximum cell length based on the device screen width
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const maxCellLength = screenWidth < 600 ? 4 : 9;

    // Format the column headers with equal gaps and right alignment
    const formattedHeaders = headers.map(header => header.padEnd(maxCellLength + 2));

    // Concatenate the column headers with a tab separator
    tableText += formattedHeaders.join("\t") + "\n";

    // Add a single line of empty space
    tableText += " ".repeat((maxCellLength + 2) * headers.length) + "\n";

    // Loop through the remaining rows and cells of the table and concatenate the text content of each cell

    for (let i = 1; i < table.rows.length; i++) {
      const row = table.rows[i];
      let rowText = "";

      // Extract the Name, Quantity, and Price values from each row
      const name = row.cells[0].innerText.trim();
      const quantity = row.cells[1].innerText.trim();
      const price = row.cells[2].innerText.trim();

      // Format the cell text and add them to the row text
      let formattedName = name.padEnd(maxCellLength + 2);
      if (formattedName.length > 12) {
        const firstLine = formattedName.slice(0, 12);
        const remainingText = formattedName.slice(12);
        const remainingLines = remainingText.match(new RegExp(`.{1,${maxCellLength + 2}}`, 'g'));
        formattedName = firstLine + "\n" + remainingLines.join("\n");
      }

      const formattedQuantity = quantity.padStart(maxCellLength - 2);  // Adjust the padding here
      const formattedPrice = price.padStart(maxCellLength + 9);  // Adjust the padding length here

      rowText = `${formattedName}\t${formattedQuantity}\t${formattedPrice}`;

      if (rowText.length > 0) {
        tableText += `${rowText}\n`;
      }

      // Add a space between each row  
      tableText += "\n";
    }

    // Calculate the total amount
    const totalAmountText = `Total Amount: ${totalAmount.toFixed(2)}`;
    tableText += totalAmountText + "\n";

    // Generate WhatsApp share link with table text
    const encodedTableText = encodeURIComponent(tableText);

    // Attempt to open WhatsApp using the custom URL scheme
    window.location.href = `whatsapp://send?text=${encodedTableText}`;

    // // Fallback to WhatsApp web after a delay
    // setTimeout(function () {
    //   window.location.href = "https://web.whatsapp.com/send?text=" + encodedTableText;
    // }, 3000); // Wait for 3 seconds before falling back

    // Open WhatsApp share link in a new window     
    // window.open(link, "_blank");

    navigate('/Success', { state: { addedSum } });

    // navigate('/Success', { state: { addedSum } });

    localStorage.removeItem('selectedProducts');
    setCartItems([]);
  };

  const totalAmount = cartItems.reduce((total, item) => {
    const itemPrice =
      customerType.toUpperCase() === 'CUSTOMER'
        ? item.quantity[item.id] * item.MOP
        : customerType.toUpperCase() === 'AGENT'
          ? item.quantity[item.id] * item.AP
          : customerType.toUpperCase() === 'JS_EMPLOYEE'
          ? item.quantity[item.id] * item.Branch
          : customerType.toUpperCase() === 'JS_MANAGER'
          ? item.quantity[item.id] * item.RealPrateTax
          : item.quantity[item.id] * item.DP;

    return total + itemPrice;
  }, 0);

  if (cartItems.length === 0) {
    return <p className='no-items'>No items in cart</p>;
  }

  return (
    <div className='container-body'>
      {cartItems.length > 0 && (
        <table className='cart-table' ref={componentRef} id='reviewlist'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Quantity</th>
              <th>Compatible Models</th>

              <th>Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item) => (
              item.quantity[item.id] > 0 && (
                <tr className='cart-item' key={item.id}>
                  <td style={{ textAlign: 'left' }} className='item-name'>
                    {item.name}
                  </td>
                  <td className='item-quantity' style={{ textAlign: 'right' }}>
                    {item.quantity[item.id]}
                  </td>
                  <td className='item-name'>{item.selectedValue}</td>

                  <td className='item-price' style={{ textAlign: 'right', width: '40%' }}>
                    {customerType.toUpperCase() === 'CUSTOMER'
                      ? (item.quantity[item.id] * item.MOP).toFixed(2)
                      : customerType.toUpperCase() === 'AGENT'
                        ? (item.quantity[item.id] * item.AP).toFixed(2)
                        : customerType.toUpperCase() === 'TK_EMPLOYEE'
                        ? (item.quantity[item.id] * item.Branch).toFixed(2)
                        : customerType.toUpperCase() === 'TK_MANAGER'
                        ? (item.quantity[item.id] * item.RealPrateTax).toFixed(2)
                        : customerType.toUpperCase() === 'JS_EMPLOYEE'
                          ? (item.quantity[item.id] * item.Branch).toFixed(2)
                          : customerType.toUpperCase() === 'JS_MANAGER'
                          ? (item.quantity[item.id] * item.RealPrateTax).toFixed(2)
                          : (item.quantity[item.id] * item.DP).toFixed(2)}
                  </td>

                  <td>
                    <button onClick={() => handleDelete(item.ID)} className="delete-icon" style={{ backgroundColor: 'transparent', border: 'none' }}>
                      <FaTrashAlt />
                    </button>
                  </td>
                </tr>
              )
            ))}

            <td></td>
            <td></td>
            <td ></td>
            <td style={{ color: 'red' }} className='item-price'>Total:<span style={{ float: 'right' }}>{totalAmount.toFixed(2)}</span></td>
            <td ></td>

          </tbody>
        </table>
      )}

      {cartItems.length > 0 && (
        <div>
          <button onClick={() => handlePrint("pdf")} className="share" style={{ height: "40px", width: "150px" }}>
            Submit
          </button>
        </div>
      )}

      {cartItems.length > 0 && (
        <div>
          <button onClick={handleClearTable} className='clear' style={{ height: '30px', width: '90px' }}>Clear Cart</button>
        </div>
      )}
    </div>
  );

};

export default Reviewlist;
