import React, { useState, useEffect } from 'react';
import './Image.css';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

function Image() {
    // State variables
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [image, setImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [imageFormat, setImageFormat] = useState(null);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiLocalUrl = process.env.REACT_APP_API_LOCAL_URL;


    const location = useLocation();
    const successMessage = location.state && location.state.successMessage;
    console.log(successMessage);

    if (successMessage === null) {
        // Return back from the page or perform any desired action
        window.history.back();
    }

    useEffect(() => {
        fetch(`${apiBaseUrl}/getCategories/JAPAN_ACCESORIES`)
            .then((response) => response.json())
            .then((data) => setCategories(data));
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            fetch(`${apiBaseUrl}/getProductByCategory/JAPAN_ACCESORIES?Id=${selectedCategory}`)
                .then((response) => response.json())
                .then((data) => setItems(data.map(item => ({
                    Id: item.ID,
                    ItemName: item.ItemName,
                }))));
        }
    }, [selectedCategory]);

    // Handle category selection
    // const handleCategoryChange = (event) => {
    //     setSelectedCategory(event.target.value);
    //     setSelectedItem('');
    //     setImage(null); // Reset the image when the category changes
    // };

    ///aswin////
    const [showItemSelect, setShowItemSelect] = useState(false); // aswin //State to manage item select visibility
    const handleCategoryChange = (selectedCategory) => {
        if (selectedCategory) {
            setSelectedCategory(selectedCategory.value);
            setSelectedItem('');
            setImage(null); // Reset the image when the category changes
            setShowItemSelect(true); // Show the item select when category is selected
        } else {
            setSelectedCategory('');
            setShowItemSelect(false); // Hide the item select if no category is selected
        }
    };
////////////////////////////////////
    // Handle item selection
    // const handleItemChange = (event) => {
    //     setSelectedItem(event.target.value);
    //     setImage(null); // Reset the image when the item changes
    // };
    // Handle item selection
// Handle item selection
const handleItemChange = (selectedItem) => {
    if (selectedItem) {
        setSelectedItem(selectedItem.value);
        setImage(null); // Reset the image when the item changes
    } else {
        setSelectedItem('');
    }
};



    // Handle image upload
    // Update the backend server base URL
    const backendBaseUrl = `${apiLocalUrl}`;
    const handleImageUpload = async (event) => {
        const selectedImage = event.target.files[0];

        // Ensure there's a selected item
        if (!selectedItem) {
            console.error('No item selected.');
            return;
        }

        setSelectedImage(selectedImage);

        // Get the selected item's ID
        const selectedItemId = selectedItem;

        // Get the image format (file extension)
        const imageFormat = selectedImage.name.split('.').pop().toLowerCase(); // Extracts the file extension

        // Create a FormData object to send the image file
        const formData = new FormData();
        formData.append('image', selectedImage);

        try {
            // Make a POST request to the server to upload the image with the selected item's ID and image format in the URL
            const response = await fetch(`${backendBaseUrl}/upload-image/${selectedItemId}.${imageFormat}`, {
                method: 'POST',
                body: formData,
            });
            console.log(response);

            if (response.ok) {
                // Image uploaded successfully
                console.log('Image uploaded successfully');

                // Set the image format in the state
                setImageFormat(imageFormat);

                // Display an alert with the response message
                const responseData = await response.json();
                window.alert(`Image uploaded successfully with the name: ${responseData}`);

            } else {
                // Handle error
                console.error('Image upload failed');
                window.alert('Image upload failed');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            window.alert('upload successfull.');
        }
    };


    //   useEffect(() => {
    //         const itemId = '64'; // Replace with the actual item ID for which you want to fetch the image
    //         const backendURL = `${apiLocalUrl}/uploads/${itemId}.jpg`; // Replace with the correct backend URL


    //         // Fetch the image URL from the backend
    //         fetch(backendURL)
    //             .then((response) => {
    //                 if (response.ok) {
    //                     setImageURL(backendURL);
    //                 } else {
    //                     console.error('Failed to fetch image from the backend');
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching image:', error);
    //             });
    //     }, []);




    // Save image with selected item's ID as the name
    useEffect(() => {
        if (image && selectedItem) {
            const imageName = `${selectedItem}.jpg`;
            // You can save the image using the imageName here.
            // For demonstration purposes, we'll just log the image name.
            console.log(`Image saved as: ${imageName}`);
        }
    }, [image, selectedItem]);


    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);

    return (
        <div className="image-container">
            <h1 className="header">Image Upload</h1>
            <div className="select-container">
                <label htmlFor="categorySelect" className="label">Select Category:</label>
                {/* <select id="categorySelect" onChange={handleCategoryChange} value={selectedCategory} className="select">
                    <option value="">Select a category</option>
                    {categories.map((category) => (
                        <option key={category.Id} value={category.Id}>
                            {category.Name}
                        </option>
                    ))}
                </select> */}
                <Select
                    id="categorySelect"
                    onChange={handleCategoryChange}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="color"
                    options={categories.map((category) => ({
                        value: category.Id,
                        label: category.Name
                    }))}
                />
            </div>
            {/* {selectedCategory && (
                <div className="select-container">
                    <label htmlFor="itemSelect" className="label">Select Item:</label>
                    <select id="itemSelect" onChange={handleItemChange} value={selectedItem} className="select">
                        <option value="">Select an item</option>
                        {items.map((item) => (
                            <option key={item.Id} value={item.Id}>
                                {item.ItemName}
                            </option>
                        ))}
                    </select>
                </div>
            )} */}

{showItemSelect && (  // Conditionally render item select
                <div className="select-container" id='itemselectdiv'>
                    <label htmlFor="itemSelect" className="label">Select Item:</label>
                    {/* <select
                        id="itemSelect"
                        onChange={handleItemChange}
                        value={selectedItem}
                        className="select"
                    >
                        <option value="">Select an item</option>
                        {items.map((item) => (
                            <option key={item.Id} value={item.Id}>
                                {item.ItemName}
                            </option>
                        ))}
                    </select> */}
                     <Select
                    id="itemSelect"
                    onChange={handleItemChange}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="color"
                    options={items.map((item) => ({
                        value: item.Id,
                        label: item.ItemName
                    }))}
                />
                </div>
            )}
            {selectedItem && (
                <div className="upload-container">
                    <label htmlFor="imageUpload" className="label">Upload Image:</label>
                    <input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="file-input"
                    />
                </div>
            )}



            {selectedImage && (
                <div className="preview-container">
                    <h2 className="preview-header">Preview:</h2>
                    <img src={URL.createObjectURL(selectedImage)} alt="Uploaded" className="uploaded-image" />
                </div>
            )}

            {/* <div>
                <h1>display main image</h1>
                {imageURL && (
                    <img src={imageURL} alt={`Imag for Item`} className="uploaded-image" />
                )}
            </div> */}

        </div>
    );
}


export default Image;
