import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";
import { FaFilePdf } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select"; 
import "./Order.css";

function Order() { 
  const [orderData, setOrderData] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [profitSum, setProfitSum] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customerNames, setCustomerNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState(null);
const deliveryStatusOptions = [
  { value: '0', label: 'Pending' },
  { value: '1', label: 'Success' },
];

// Define the handleDeliveryStatusChange function
const handleDeliveryStatusChange = (selectedOption) => {
  setSelectedDeliveryStatus(selectedOption);
};


  const Ledcode = sessionStorage.getItem('Ledcode');
  // console.log("Ledcode:", Ledcode);

  const AgentCode = sessionStorage.getItem('AgentCode');
  // const AgentCode = rawAgentCode === "0" ? "-1" : rawAgentCode;
  // console.log("AgentCode:", AgentCode);

  const customerType = sessionStorage.getItem('CustomerType');

  

  if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {
    window.history.back();
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiBaseUrl}/OrderList/JAPAN_ACCESORIES?&customer=${searchQuery}&startDate=${startDate}&endDate=${endDate}&ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`);

        const data = await response.json();
        // console.log("Order Data:", data); 

        const sortedData = data.sort((a, b) => a.InvoiceNo - b.InvoiceNo);
        // console.log("Sorted Data:", sortedData); 

        // Filter data based on the selected date range

      const filteredData = sortedData.filter((item) => {
        const orderDate = new Date(item.DDate);
        const withinDateRange =
          (!startDate || orderDate >= startDate) &&
          (!endDate || orderDate <= new Date(endDate.getTime() + 24 * 60 * 60 * 1000));   
        return withinDateRange;
      });


        setOrderData(filteredData);

        const total = filteredData.reduce((sum, item) => sum + item.Total, 0);
        const profit = filteredData.reduce((sum, item) => sum + item.Profit, 0);
        setTotalSum(total);
        setProfitSum(profit);

        // Extract unique customer names from the filtered data
        
        const uniqueCustomerNames = [...new Set(filteredData.map(item => item.Customer))];
        setCustomerOptions(
          uniqueCustomerNames.map(name => ({ value: name, label: name }))
        );
      } catch (error) {
        // console.log("Error fetching order data:", error);
      }
    };

    fetchData();
        // Set up polling to fetch data every X milliseconds (e.g., every 5 seconds)
        const pollingInterval = 5000; // 5 seconds
        const pollingId = setInterval(fetchData, pollingInterval);
   
        // Clean up the interval when the component unmounts
         return () => {
         clearInterval(pollingId);
       };
  }, [searchQuery, startDate, endDate,AgentCode,Ledcode]);

  function formatDate(date) {
    if (!date) {
      return ''; // Return an empty string if the date is undefined 
    }

    const parts = date.split('-'); // Assuming date format is 'YYYY-MM-DD'

    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[0], 10);

    return `${padNumber(day)}-${padNumber(month)}-${year}`;
  }

  function padNumber(number) {
    return number.toString().padStart(2, "0");
  }

  const PDFDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerSection}>
          <Text style={styles.header}>Order List</Text>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.headerRow]}>
            <View style={[styles.tableCell, styles.headerCell]}>
              <Text style={[styles.headerText, styles.smallHeaderText]}>Date</Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, { width: "12%" }]}>
              <Text style={[styles.headerText, styles.smallHeaderText]}>Entry No</Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, { width: "50%" }]}>
              <Text style={[styles.headerText, styles.smallHeaderText]}>Customer</Text>
            </View>
            <View style={[styles.tableCell, styles.headerCell, { width: "15%" }]}>
              <Text style={[styles.headerText, styles.smallHeaderText]}>Total</Text>
            </View>
            {customerType !== 'DEALER' && (
            <View style={[styles.tableCell, styles.headerCell, { width: "15%" }]}>
              <Text style={[styles.headerText, styles.smallHeaderText]}>Profit</Text>
            </View>
                )}

          </View>

          {filteredOrderData.map((item, index) => (
            <View style={[styles.tableRow, index % 2 === 0 && styles.evenRow]} key={index}>
              <View style={[styles.tableCell, styles.borderRight]}>
                <Text style={styles.cellText}>{formatDate(item.DDate)}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderRight, { width: "12%" }]}>
                <Text style={styles.cellText}>{item.InvoiceNo}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderRight, { width: "50%" }]}>
                <Text style={styles.cellText}>{item.Customer}</Text>
              </View>
              <View style={[styles.tableCell, styles.borderRight, styles.alignRight, { width: "15%" }]}>
                <Text style={styles.cellText}>{item.Total.toFixed(2)}</Text>
              </View>
              {customerType !== 'DEALER' && (
              <View style={[styles.tableCell, styles.alignRight, { width: "15%" }]}>
                <Text style={styles.cellText}>{item.Profit.toFixed(2)}</Text>
              </View>
                  )}

            </View>
          ))}
          <View style={[styles.tableRow, styles.totalRow]}>
            <View style={[styles.tableCell, styles.borderRight, styles.totalCell]}>
              <Text style={[styles.cellText, styles.totalText]}>Total:</Text>
            </View>
            <View style={[styles.tableCell, styles.borderRight, styles.totalCell, { width: "12%" }]}>
              <Text style={[styles.cellText, styles.totalText]}>{filteredOrderData.length}</Text>
            </View>
            <View style={[styles.tableCell, styles.borderRight, styles.totalCell, { width: "50%" }]}></View>
            <View style={[styles.tableCell, styles.borderRight, styles.totalCell, styles.alignRight, { width: "15%" }]}>
              <Text style={[styles.cellText, styles.totalText]}>{filteredTotalSum.toFixed(2)}</Text>
            </View>
            {customerType !== 'DEALER' && (
            <View style={[styles.tableCell, styles.totalCell, styles.alignRight, { width: "15%" }]}>
              <Text style={[styles.cellText, styles.totalText]}>{filteredProfitSum.toFixed(2)}</Text>
            </View>
                )}

          </View>
        </View>
      </Page>
    </Document>
  );

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#FFFFFF",
      padding: 40,
    },
    headerSection: {
      marginBottom: 20,
    },
    header: {
      fontSize: 24,
      textAlign: "center",
      fontWeight: "bold",
      color: "#333333",
    },

    table: {
      display: "table",
      width: "100%",
      borderCollapse: "collapse",
    },
    tableRow: {
      flexDirection: "row",
    },
    evenRow: {
      backgroundColor: "#F9F9F9",
    },

    tableCell: {
      width: "20%", // Increase the width value as needed
      paddingVertical: 10,
      paddingHorizontal: 5,
      borderWidth: 1,
      borderColor: "#CCCCCC",
    },

    cellText: {
      fontSize: 6,
      color: "#333333",
    },

    alignRight: {
      textAlign: "right",
    },

    headerRow: {
      backgroundColor: "#DDDDDD",
      borderBottomWidth: 2,
    },

    headerCell: {
      borderBottomWidth: 1,
    },

    headerText: {
      fontWeight: "bold",
      color: "#333333",
      fontSize: 14, // Updated font size for header text
    },

    totalRow: {
      borderTopWidth: 2,
    },

    totalCell: {
      paddingVertical: 10,
      paddingHorizontal: 5,
    },

    totalText: {
      fontWeight: "bold",
      color: "#333333",
      textAlign: "right",
    },

    borderRight: {
      borderRightWidth: 1,
    },
    
    smallHeaderText: {
      fontSize: 10, // Added style for smaller header text size
    },
  });

  function exportToExcel() {
    // Add heading row to CSV content
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Date,Entry No,Customer,Total";
  
    // Add Profit column only if the customerType is not 'DEALER'
    if (customerType !== 'DEALER') {
      csvContent += ",Profit";
    }
  
    csvContent += "\n";
  
    // Add order data rows to CSV content
    csvContent += filteredOrderData
      .map(item => {
        let row = [formatDate(item.DDate), item.InvoiceNo, item.Customer, item.Total.toFixed(2)];
  
        // Add Profit value only if the customerType is not 'DEALER'
        if (customerType !== 'DEALER') {
          row.push(item.Profit.toFixed(2));
        }
  
        return row.join(",");
      })
      .join("\n");
  
    // Add total row to CSV content
    csvContent += `\nTotal,${filteredOrderData.length},,${filteredTotalSum.toFixed(2)}`;
  
    // Add Profit total only if the customerType is not 'DEALER'
    if (customerType !== 'DEALER') {
      csvContent += `,${filteredProfitSum.toFixed(2)}`;
    }
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "order_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
 // Modify the existing filteredOrderData using the selectedDeliveryStatus filter
const filteredOrderData = orderData.filter((item) => {
  const orderDate = new Date(item.DDate);
  const matchesSearchQuery = item.Customer.toLowerCase().includes(searchQuery.toLowerCase());
  const withinDateRange =
    (!startDate || orderDate >= startDate) &&
    (!endDate || orderDate <= new Date(endDate.getTime() + 24 * 60 * 60 * 1000));

  const matchesDeliveryStatus = !selectedDeliveryStatus || item.DeliveryStatus.toString() === selectedDeliveryStatus.value;

  if (selectedCustomer) {
    return (
      item.Customer.toLowerCase() === selectedCustomer.toLowerCase() &&
      matchesDeliveryStatus &&
      withinDateRange
    );
  }

  return matchesSearchQuery && matchesDeliveryStatus && withinDateRange;
});

const filteredTotalSum = filteredOrderData.reduce((sum, item) => sum + item.Total, 0);
const filteredProfitSum = filteredOrderData.reduce((sum, item) => sum + item.Profit, 0);

  const handleCustomerChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCustomer(selectedOption.value);
    } else {
      setSelectedCustomer(null);
    }
  };

  return (
    <div id="order-main" className="homepage-container">
      <div className="home-container">
        <img
          src="https://i.postimg.cc/FFc0w6KZ/ALIK-LOGO.png"
          alt="Example"
          className="example-image"
        />
        <h2 id="home1">Order List</h2>
      </div>

      <div className="download-options">

    {/* PDF download link */}
        {filteredOrderData.length > 0 && (
          <PDFDownloadLink document={<PDFDocument />} fileName="order_list.pdf">
            {({ blob, url, loading, error }) => (
              <div className="download-link">
                <img style={{ height: '30px' }} src="https://i.postimg.cc/TYcDhjmn/pdf-image.png" alt="PDF Download" />
              </div>
            )}
          </PDFDownloadLink>    
        )}

        {/* Excel download link */}
        <div className="download-link2">
          <a href="#" onClick={exportToExcel}>
            <img style={{ height: '30px' }} src="https://i.postimg.cc/Kc9144S4/excel.png" alt="Excel Icon" />
          </a>
        </div>
      </div>  

      <div className="datepicker">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          dateFormat="dd-MM-yyyy"
        />

        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          placeholderText="End Date"
          dateFormat="dd-MM-yyyy"
        />

      </div>

      <div id="customer-dropdown">     
        <Select
          options={customerOptions}
          value={selectedCustomer ? { value: selectedCustomer, label: selectedCustomer } : null}
          onChange={handleCustomerChange}
          isClearable
          placeholder= "Select Customer..."  
            
          
        />
      </div>

      <div id="status-dropdown">     
  <Select
    options={deliveryStatusOptions}
    value={selectedDeliveryStatus}
    onChange={handleDeliveryStatusChange}
    isClearable
    placeholder="Select Delivery Status..."
  />
</div>

      <div className="content2">
        <table className="excel-table">

          <thead>
            <tr>
              <th id="date">Date</th>
              <th id="entryno">Entry No</th>
              <th>Customer</th>
              <th>Total</th>
              {customerType !== 'DEALER' && (
              <th>Profit</th>
              )}

              <th id="delivery">Delivery Status</th>
            </tr>
          </thead> 
          <tbody>
            {filteredOrderData.map((item, index) => (   
              <tr
                key={index}
                className={selectedCustomer === item.Customer ? "selected-row" : ""}
                onClick={() => setSelectedCustomer(item.Customer)}
              >  
                <td>{formatDate(item.DDate)}</td>        
                <td>{item.InvoiceNo}</td>         
                <td>{item.Customer}</td>  
                <td id="total-amount">{item.Total.toFixed(2)}</td>
                {customerType !== 'DEALER' && (
                <td style={{ textAlign: 'right' }}>{item.Profit.toFixed(2)}</td>
                )}

                <td
                  style={{
                    textAlign: 'center',   
                    color: item.DeliveryStatus === 1 ? 'green' : item.DeliveryStatus === 0 ? 'red' : 'inherit',
                    fontWeight: item.DeliveryStatus === 1 || item.DeliveryStatus === 0 ? 'bold' : 'normal',
                  }}
                >   
                  {item.DeliveryStatus === 0 ? "Pending" : item.DeliveryStatus === 1 ? "Success" : item.DeliveryStatus}
                </td>
              </tr>  
            ))}   
            <tr>
              <td id="total-text">Total:</td>   
              <td id="total-entry">{filteredOrderData.length}</td>  
              <td></td>
              <td id="total-sum">{filteredTotalSum.toFixed(2)}</td>     
              {customerType !== 'DEALER' && (
              <td id="total-profit">{filteredProfitSum.toFixed(2)}</td>      
              )}
  
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default Order;