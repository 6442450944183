import Navbar from "./Navbar";
import React from "react";
import { Link } from "react-router-dom";
import "./Notification.css";
import { useEffect } from "react";
import Cookies from "js-cookie";

function Notification() {

  // Retrieve the stored session value 
  const customerType = sessionStorage.getItem('CustomerType');

  // Use the retrieved value as needed
  // console.log("customertype:", customerType);  // Example: Output the customer type to the console

  useEffect(() => {
    // Push a new state to the history stack when the component mounts
    window.history.pushState(null, null, window.location.pathname);

    // Add a listener to the 'popstate' event
    window.addEventListener('popstate', handlePopstate);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);


  const handlePopstate = () => {
    // Push another state to the history stack, effectively preventing the user from going back
    window.history.pushState(null, null, window.location.pathname);
  };

  // useEffect(() => {

  //   const handleBeforeUnload = () => {

  //    window.requestAnimationFrame(() => {
  //       window.location.href = '/';
  //     });
  //   };

  //   const handleUnload = () => {


  //     window.requestAnimationFrame(() => {
  //       window.location.href = '/';
  //     });

  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   window.addEventListener('unload', handleUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, []);

  const LedName = sessionStorage.getItem("LedName");
  // console.log("ledname:", LedName);

  const Ledcode = sessionStorage.getItem('Ledcode');
  // console.log("Ledcode:", Ledcode);

  if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {
    // Return back from the page or perform any desired action
    window.history.back();
  }

  const handleLogOut = () => {
    sessionStorage.removeItem("Ledcode");
    sessionStorage.removeItem("LedName");
    localStorage.removeItem('selectedProducts');

    sessionStorage.clear();

    Cookies.remove("isLoggedIn");
    Cookies.remove("username");
    Cookies.remove("customerType");
    Cookies.remove("ledCode");
    Cookies.remove("ledName");
  };

 return (
    <div className="homepage-container"  >
      <div className="home-container">
        <img
          src="https://i.postimg.cc/FFc0w6KZ/ALIK-LOGO.png"
          alt="Example"
          className="example-image"
        />
        <h2 id="home1">Profile</h2>
      </div>
      <div className="LedName">{LedName !== null && <p>Hi, {LedName}!</p>}</div>

      <div className="content">
        <div className="Button">
          {(customerType === 'AGENT' || customerType === 'DEALER'  || customerType === 'JS_MANAGER'  || customerType === 'JS_EMPLOYEE' || customerType === 'TK_MANAGER'  || customerType === 'TK_EMPLOYEE') && <Link to="/Main">Home</Link>}
          <br />

          <Link to="/About">ABOUT US</Link>
          <br />
  
          <Link to="/" onClick={handleLogOut}>
            Log Out   
          </Link>
        </div>

      </div>   

      <div className="container3">
        <Navbar />
      </div>
    </div>
  );
}

export default Notification;
