import React from 'react';
import { useEffect } from "react";

import { Link } from 'react-router-dom';
import './About.css';

function About() {

  const Ledcode = sessionStorage.getItem('Ledcode');


if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {
  
  window.history.back();
}

 
  return (
    <div className='about-main'>
      <div className="about-container">
        <div style={{ display: 'flex' }}>
          <img src="https://i.postimg.cc/FFc0w6KZ/ALIK-LOGO.png" alt="Example" className="example-image" style={{ backgroundColor: 'white', width: '90px', height: '90px' }} />


          <h1 id='alik' style={{ textAlign: 'center' }}>ALIK STORE</h1>
        </div>
        <p id='description' className="about-description">


          <br />
          <h3 id='about1'>About Us</h3>

          Welcome to ALIK STORE, your ultimate destination for all your online shopping needs. We are a leading ecommerce company committed to providing our customers with a seamless and enjoyable shopping experience. At ALIK STORE, we strive to bring you a vast selection of high-quality products from various categories, all conveniently accessible from the comfort of your own needs.

          <br />

          <h3 id='about1'>Our Vision</h3>



          Our vision at ALIK STORE is to revolutionize the way people shop by leveraging the power of technology and innovation. We aim to be the go-to online destination where customers can find everything they need, ranging from everyday essentials to unique and exclusive items. We believe in the limitless potential of ecommerce to bring convenience, value, and joy to people's lives.

          <br />

          <h3 id='about1'>Wide Range of Products</h3>

          With an extensive catalog of products, we cater to the diverse needs and preferences of our customers. you'll find an incredible array of options to choose from. We carefully curate our inventory, partnering with trusted suppliers and brands to ensure that every item we offer meets the highest standards of quality and reliability.

          <br />

          <h3 id='about1'>User-Friendly Shopping Experience</h3>

          We understand that convenience and simplicity are paramount when it comes to online shopping. Our website is designed with a user-friendly interface that makes browsing and finding products a breeze. Our advanced search and filtering options allow you to quickly locate the items you're looking for, and our detailed product descriptions and customer reviews provide valuable insights to help you make informed decisions.


          <br />
          <h3 id='about1'>Exceptional Customer Service</h3>

          We value each and every customer and strive to provide exceptional customer service at all times. Our dedicated support team is readily available to assist you with any inquiries, concerns, or feedback you may have. Whether it's assistance with order, product recommendations, or resolving any issues that may arise, we are here to ensure your complete satisfaction.

          <br />
          <h3 id='about1'>Community and Social Responsibility</h3>

          At ALIK STORE, we believe in giving back to the community and being socially responsible. We actively seek opportunities to support charitable causes, environmental sustainability, and initiatives that make a positive impact on society. By shopping with us, you become a part of this mission, as a percentage of our profits goes towards supporting these endeavors.

          <br />
          <br />
          Thank you for choosing ALIK STORE as your preferred online shopping destination. We look forward to serving you and exceeding your expectations with our exceptional products, customer service, and commitment to making your shopping experience truly remarkable.

        </p>
        <p className="about-description">
          ALIK STORE, established in 2022, is one of Kerala's leading wholesalers with 25000+ buyers with capabilities to deliver across 100% of serviceable PIN codes in Kerala.


        </p>


      </div><br />
      <div className="address">
        <img src="https://i.postimg.cc/FFc0w6KZ/ALIK-LOGO.png" alt="Example" className="example-image" id='about-image' />
        <div id='about-address'>
          <br />
          <br />
          Gulf bazar,<br />
          Tirur Malappuram,<br />
          Kerala 676101,<br />
          <i class="fas fa-phone"></i>&nbsp; +91 88484 83211<br />
          <i class="fas fa-envelope"></i>&nbsp; alikstore.ind@gmail.com<br /><br />
          <div id='footer4'>
          Copyright © 2023 SherSoft <br/>
           <a id='hyperlink'  href="https://shersoftware.com/" target="_blank" rel="noopener noreferrer">shersoftware.com</a>
          </div>
         
        </div>

        {/* Copyright Section */}
        <div id='footer5'>
          Copyright © 2023 SherSoft &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; <a id='hyperlink'  href="https://shersoftware.com/" target="_blank" rel="noopener noreferrer">shersoftware.com</a>

        </div>
      </div>
    </div>

  );
}

export default About;
