import React, { useEffect, useState } from 'react';
import './Search.css'
import '@fortawesome/fontawesome-free/css/all.css';
import { Link } from 'react-router-dom';
import ImageModal from './Imagemodal';
import Navbar from '../components/Navbar';
import { LRUCache } from 'lru-cache';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const cache = new LRUCache({ max: 30 });

const Search = (props) => {
    const [items, setItems] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const encodedSearchTerm = sessionStorage.getItem('searchofTerm');
    const session = encodedSearchTerm ? decodeURIComponent(encodedSearchTerm) : '';
    const [selectedItems, setSelectedItems] = useState([]);
    const [quantity, setQuantities] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [extraImages, setExtraImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiLocalUrl = process.env.REACT_APP_API_LOCAL_URL;
    const [showToast, setShowToast] = useState(false);

    const Ledcode = sessionStorage.getItem('Ledcode');
    // console.log("Ledcode:", Ledcode);    

    if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {
        // Return back from the page or perform any desired action
        window.history.back();
    }

    const customerType = sessionStorage.getItem('CustomerType');

    const handleDropdownChange = (selectedOption, item) => {
        const selectedValue = selectedOption ? selectedOption.value : '';
        setSelectedValue(selectedValue); // Update selectedValue state
        // Update the selected value for the specific item
        setItems(prevItems => {
            return prevItems.map(prevItem => {
                if (prevItem.ID === item.ID) {
                    return {
                        ...prevItem,
                        selectedValue: selectedValue // Save the selected value for this item
                    };
                } else {
                    return prevItem;
                }
            });
        });
        handleSaveButtonClick(
            item.ID,
            item.name,
            quantity,
            item.MRP,
            item.DP,
            item.AP,
            item.MOP,
            item.imageUrl,
            item.Qty,
            item.Branch,
            item.RealPrateTax,
            selectedValue
        );
    
       
    };
    
    const clearSelectedValue = (itemId) => {
        setSelectedValue('');
    
        // Update the selected value for the specific item in the items state
        setItems(prevItems => {
            return prevItems.map(prevItem => {
                if (prevItem.ID === itemId) {
                    return {
                        ...prevItem,
                        selectedValue: '' // Clear the selectedValue for this item
                    };
                } else {
                    return prevItem;
                }
            });
        });
    };
    

    // Call this function where you need to clear the selected value


    useEffect(() => {
        const fetchData = async () => {
            try {
                const encodedSearchTerm = sessionStorage.getItem('searchofTerm');
                const session = encodedSearchTerm ? decodeURIComponent(encodedSearchTerm) : '';

                if (cache.has(session)) {
                    const cachedData = cache.get(session);
                    setItems(cachedData);
                    setLoading(false);
                } else {
                    const response = await fetch(`${apiBaseUrl}/getProductByFilter/JAPAN_ACCESORIES?filter=${encodeURIComponent(session)}`);
                    const data = await response.json();

                    const items = await Promise.all(
                        data.map(async (item) => {
                            try {
                                const dropdownURL = `${apiBaseUrl}/getProductByNewFilter/JAPAN_ACCESORIES?filter=${encodeURIComponent(item.ItemName)}`;
                                const dropdownResponse = await fetch(dropdownURL);
                                if (!dropdownResponse.ok) {
                                    throw new Error('Network response was not ok');
                                }
                                const dropdownData = await dropdownResponse.json();
                                const dropdownOptions = dropdownData.map(option => option.model);

                                return {
                                    ID: item.ID,
                                    name: item.ItemName,
                                    MRP: item.MRP,
                                    MOP: item.MOP,
                                    DP: item.DP,
                                    AP: item.AP,
                                    Branch: item.Branch,
                                    RealPrateTax: item.RealPrateTax,
                                    Qty: item.Qty,
                                    imageUrl: `${apiLocalUrl}/uploads/${item.ID}.jpg?v=${Date.now()}`,
                                    dropdownOptions: dropdownOptions
                                }
                            } catch (error) {
                                console.error('Error fetching dropdown options:', error);
                                return {  // Return item with empty dropdown options if fetch fails
                                    ID: item.ID,
                                    name: item.ItemName,
                                    MRP: item.MRP,
                                    MOP: item.MOP,
                                    DP: item.DP,
                                    AP: item.AP,
                                    Branch: item.Branch,
                                    RealPrateTax: item.RealPrateTax,
                                    Qty: item.Qty,
                                    imageUrl: `${apiLocalUrl}/uploads/${item.ID}.jpg?v=${Date.now()}`,
                                    dropdownOptions: [],
                                };
                            }
                            })
                    );
                    setItems(items);
                    cache.set(session, items);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const fetchImageUrl = async (ItemName) => {
        try {
            const encodedItemName = encodeURIComponent(ItemName);
            const imageUrlEndpoint = `${apiBaseUrl}/stock/getProductImages/JAPAN_ACCESORIES?itemname=${encodedItemName}`;
            const response = await fetch(imageUrlEndpoint);
            const imageData = await response.json();

            if (Array.isArray(imageData) && imageData.length > 0) {
                const photoData = imageData[0]?.photo?.data;

                if (photoData) {
                    const base64String = arrayBufferToBase64(photoData);
                    return `data:image/jpeg;base64,${base64String}`;
                }
            }

            return 'https://example.com/default-image.jpg';
        } catch (error) {
            console.error('Error fetching image:', error);
            return 'https://example.com/default-image.jpg';
        }
    };

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;

        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return btoa(binary);
    };


    const handleSaveButtonClick = (ID, itemName, quantity, MRP, MOP, DP, AP, imageSrc, Qty, Branch, RealPrateTax, selectedValue) => {
        // console.log("MOP:", MOP);
        // console.log("DP:", DP);
        // console.log("AP:", AP);
        console.log("selectedValue:", selectedValue);



        if (quantity === null || quantity === "" || (typeof quantity === 'object' && (quantity.undefined === "" || quantity.undefined === "0"))) {
            return;
        }
        if (quantity === null || quantity === "" || (typeof quantity === 'object' && Object.keys(quantity).length === 0)) {
            return;
        }
        if (quantity === null || quantity === "" || (typeof quantity === 'object' && (quantity.undefined === "" || quantity.undefined.startsWith("-") || isNaN(quantity.undefined)))) {
            return;
        }
        if (quantity === null || quantity === "" || (typeof quantity === 'object' && Object.keys(quantity).length === 0)) {
            return;
        }


        if (quantity <= 0) {
            return;
        }

        // console.log(Qty);

        if (quantity > Qty) {
            alert('Out of stock');
            return;
        }

        const existingItemIndex = selectedItems.findIndex(item => item.selectedValue === selectedValue && item.ID === ID);
        if (existingItemIndex !== -1) {
            const updatedSelectedItems = [...selectedItems];
            updatedSelectedItems[existingItemIndex].quantity = quantity; // Update the existing item quantity with the new value
            setSelectedItems(updatedSelectedItems);

            if (quantity === 0) {
                // Remove the item from selectedItems and local storage if the new quantity is zero
                updatedSelectedItems.splice(existingItemIndex, 1);
                setSelectedItems(updatedSelectedItems);

                const existingProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];
                const updatedProducts = existingProducts.filter(item => item.selectedValue === selectedValue && item.ID === ID);
                localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
            }

        } else {
            const newSelectedItem = { ID: ID, name: itemName, quantity: quantity, MRP: MRP, AP: DP, imageSrc: imageSrc, Qty: Qty, DP: MOP, MOP: AP, Branch: Branch, RealPrateTax: RealPrateTax, selectedValue: selectedValue };
            setSelectedItems(prevSelectedItems => [...prevSelectedItems, newSelectedItem]);

            if (quantity === 0) {
                return; // Don't save to local storage if the quantity is zero
            }

            const existingProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];
            const existingProductIndex = existingProducts.findIndex(item => item.selectedValue === selectedValue && item.ID === ID);
            if (existingProductIndex !== -1) {
                const updatedProducts = [...existingProducts];
                updatedProducts[existingProductIndex].quantity = quantity; // Update the existing product quantity with the new value
                localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
            } else {
                const newProduct = { ID: ID, name: itemName, quantity: quantity, MRP: MRP, AP: DP, imageSrc: imageSrc, Qty: Qty, DP: MOP, MOP: AP, Branch: Branch, RealPrateTax: RealPrateTax, selectedValue: selectedValue };
                const updatedProducts = [...existingProducts, newProduct];
                localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
                // console.log(updatedProducts);
            }
        }

         // Call clearSelectedValue with item ID
         clearSelectedValue(ID);

       

        // Show toast notification
        setShowToast(true);

        // // show notification
        toast.success('Item added to cart!', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const handleQuantityChange = (id, e) => {
        const value = e.target.value;

        setQuantities((prevQuantities) => {
            return {
                ...prevQuantities,
                [id]: value,
            };
        });
    };

    const handleImageClick = (imageUrl) => {
        // Find the selected item based on its image URL
        const selectedItem = items.find(item => item.imageUrl === imageUrl);

        // If no item is selected, return
        if (!selectedItem) {
            return;
        }

        // Make a GET request to the extra image API using the selected item's name/id
        fetch(`${apiBaseUrl}/stock/getImages/JAPAN_ACCESORIES?itemname=${encodeURIComponent(selectedItem.name)}&itemid=${selectedItem.ID}`)
            .then(response => response.json())
            .then(data => {
                // Decode the images and update the extraImages state with the retrieved data
                const decodedImages = data.map(image => {
                    const base64String = btoa(
                        new Uint8Array(image.Image.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
                    );
                    const imageUrl = `data:${image.Image.contentType};base64,${base64String}`;
                    const altText = image.alt; // Retrieve the alt attribute from the API response
                    return { imageUrl, altText }; // Return an object with both imageUrl and altText
                });

                setExtraImages(decodedImages);
                setSelectedImage(imageUrl);
                // console.log('Extra images retrieved from API:', decodedImages);
            })
            .catch(error => {
                console.error('Error fetching extra images:', error);
            });
    };

    return (
        <div className="app1">
            <div className="homepage-container">

                <div className='search-page'>
                    <h1>Search term: "{session}"</h1>
                    <Link to="/Home">Home</Link>

                </div>
                {showToast && ( // Render ToastContainer conditionally
                    <ToastContainer
                        position="top-right"
                        autoClose={100} // Auto close after 3 seconds
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                )}

                {loading ? (
                    <div className="loading-animation">
                        {/* Insert your loading animation component here */}
                        <div className="loader"></div>
                        <p>Loading<span className="blink-animation">...</span></p>
                    </div>
                ) : (
                    items.map((item) => (
                        <div onClick={(e) => e.stopPropagation()} className="items-container">

                            <div id='items' key={item.ID} className="item">
                                <div className="image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img
                                        src={item.imageUrl}
                                        alt={item.ItemName}
                                        width={150}
                                        height={150}
                                        className="item-image"
                                        onClick={() => handleImageClick(item.imageUrl)}
                                    />
                                    {item.dropdownOptions.length > 0 && (
                                                                    <div className='reactSelectDiv'>
                                                                        <Select
                                                                            options={item.dropdownOptions.map(option => ({ value: option, label: option }))}
                                                                            value={item.selectedValue ? { value: item.selectedValue, label: item.selectedValue } : null}
                                                                            onChange={(selectedOption) => handleDropdownChange(selectedOption, item)}
                                                                            placeholder="Select compatible model"
                                                                            defaultValue={{ value: '', label: 'Select compatible model' }}
                                                                            classNamePrefix='reactSelectDiv2'
                                                                            styles={{
                                                                                control: (provided) => ({
                                                                                    ...provided,
                                                                                    fontSize: '14px', // Adjust the font size as needed
                                                                                    textAlign: 'left',
                                                                                }),
                                                                                dropdownIndicator: (provided) => ({
                                                                                    ...provided,
                                                                                    fontSize: '14px', // Adjust the font size of the dropdown indicator
                                                                                }),
                                                                                option: (provided) => ({
                                                                                    ...provided,
                                                                                    fontSize: '14px', // Adjust the font size of the options in the dropdown
                                                                                    textAlign: 'left'
                                                                                }),
                                                                                // menu: (provided) => ({
                                                                                //     ...provided,
                                                                                //     maxHeight: '85px',
                                                                                //     overflowY: 'auto'
                                                                                // })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}


                                </div>


                                <span id="h1">
                                    <span id='h9' style={{ fontSize: '15px' }}>
                                        {item.name} <br />
                                    </span>


                                    <span id="h2">
                                        {customerType === 'AGENT' && (
                                            <span>
                                                <span id="h3">AP:</span> {item.AP}{' '}
                                            </span>
                                        )}

                                        {customerType === 'DEALER' && (
                                            <span>
                                                <span id="h3">DP:</span> {item.DP}{' '}
                                            </span>
                                        )}

                                        {(customerType === 'JS_EMPLOYEE' || customerType === 'TK_EMPLOYEE') && (
                                            <span>
                                                <span id="h3">Branch: {item.Branch}{' '}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span id="h3">MRP: {item.MRP}{' '}</span>

                                            </span>
                                        )}


                                        {(customerType === 'JS_MANAGER' || customerType === 'TK_MANAGER') && (
                                            <span>
                                                <span id="h3">Rate: {item.RealPrateTax}{' '}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span id="h3">MRP: {item.MRP}{' '}</span>

                                            </span>
                                        )}

                                        {(customerType !== 'JS_EMPLOYEE' && customerType !== 'JS_MANAGER' && customerType !== 'TK_MANAGER' && customerType !== 'TK_EMPLOYEE') && (
                                            <span>
                                                <span id="h3">MOP:{item.MOP}{' '}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span id="h3">MRP: {item.MRP}{' '}</span>
                                            </span>
                                        )}
                                    </span>
                                </span>

                                {customerType !== 'AGENT' && (
                                    <input
                                        type="number"
                                        min={1}
                                        placeholder=""
                                        className="item-quantity"
                                        inputMode="numeric"
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                event.target.blur(); // Trigger onBlur event when Enter key is pressed
                                                event.target.value = ''; // Clear the input field after handling
                                            }
                                        }}
                                        onKeyPress={(event) => {
                                            // Check for mobile "Done" key press
                                            if (event.key === 'Enter' || event.key === 'Done') {
                                                event.target.blur(); // Trigger onBlur event
                                                event.target.value = ''; // Clear the input field after handling
                                            }
                                        }}
                                        onBlur={(event) => {
                                            handleQuantityChange(item.id, { target: { value: '' } });
                                            if (quantity[item.id] > item.Qty) {
                                                // If quantity exceeds available stock, display out of stock message
                                                const modalOverlay = document.createElement('div');
                                                modalOverlay.style.position = 'fixed';
                                                modalOverlay.style.top = '0';
                                                modalOverlay.style.left = '0';
                                                modalOverlay.style.width = '100%';
                                                modalOverlay.style.height = '100%';
                                                modalOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                                                modalOverlay.style.zIndex = '9999'; 
                                                document.body.appendChild(modalOverlay);
                                                const alertBox = document.createElement('div');
                                                alertBox.innerHTML = 'Available stock is ' + item.Qty + ' Nos';
                                                alertBox.style.position = 'fixed';
                                                alertBox.style.top = '50%';
                                                alertBox.style.left = '50%';
                                                alertBox.style.transform = 'translate(-50%, -50%)';
                                                alertBox.style.backgroundColor = '#fff';
                                                alertBox.style.padding = '20px';
                                                alertBox.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.5)';
                                                alertBox.style.zIndex = '10000';
                                                modalOverlay.appendChild(alertBox);

                                                const inputId = `input-${item.id}`;
                                                setTimeout(() => {
                                                    const inputElement = document.getElementById(inputId);
                                                    if (inputElement) {
                                                        inputElement.focus(); // Return focus to the input element
                                                    }
                                                    modalOverlay.remove(); // Remove the modal overlay and alert box after a delay
                                                }, 3000); // Delay removal for 3 seconds
                                                return;
                                            }
                                            // If quantity is valid, handle save button click
                                            handleSaveButtonClick(item.ID, item.name, quantity, item.MRP, item.DP, item.AP, item.MOP, item.Qty, item.imageSrc, item.Branch, item.RealPrateTax, selectedValue);
                                            event.target.value = ''; // Clear the input field after handling
                                        }}
                                        onChange={(e) => handleQuantityChange(item.id, e)}
                                        id={`input-${item.id}`} // Use item ID as the input ID
                                    />
                                )}


                            </div>

                            {/* Render image modal */}
                            {selectedImage && (
                                <ImageModal selectedImage={selectedImage} extraImages={extraImages} onCloseModal={() => setSelectedImage(null)} />
                            )}

                        </div>
                    ))
                )}

                <div className="App">
                    <Navbar />
                </div>
            </div>
        </div>
    );
};

export default Search;

