import React from 'react'
import './Privacy.css'

function Privacy() {
    return (
        <div className='privacy-policy'>
          <h1>Privacy Policy</h1>
          <p>
            At ALIK STORE, we are committed to protecting the privacy and security of our customers' personal information. This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to safeguard it.
          </p>
          
          <h2>Information We Collect</h2>
          <p>
            We may collect personal information from you when you visit our website, make a purchase, or interact with us through other channels. This information may include your name, email address, shipping address, phone number, payment details, and any other information you provide to us.
          </p>
          
          <h2>How We Use Your Information</h2>
          <p>
            We use the personal information we collect for various purposes, including processing your orders, providing customer support, improving our products and services, and communicating with you about promotions and special offers. We may also use your information to comply with legal obligations and prevent fraud.
          </p>
          
          <h2>Information Sharing</h2>
          <p>
            We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or as necessary to fulfill your orders (e.g., sharing your shipping information with our delivery partners).
          </p>
          
          <h2>Security Measures</h2>
          <p>
            We take reasonable measures to protect the confidentiality and security of your personal information. This includes implementing appropriate technical and organizational measures to prevent unauthorized access, disclosure, alteration, or destruction of your information.
          </p>
          
          <h2>Changes to this Privacy Policy</h2>
          <p>
            We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on this page, and we encourage you to review this Privacy Policy periodically for any updates.
          </p>
          
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at alikstore.ind@gmail.com.
          </p>
          
          <p>
            Last updated: [27/feb/2024]
          </p>
        </div>
      );
    }
    

export default Privacy