import React from 'react';
import Navbar from './Navbar';
import './Cart.css';
import Reviewlist from './Reviewlist';

function Cart() {
  
  return (
    <div className="homepage-container">
      <div className="home-container">
        <img src="https://i.postimg.cc/FFc0w6KZ/ALIK-LOGO.png" alt="Example" className="example-image" />
        <h2 id='home1'>Cart</h2>
      </div>

      <div className='reviewlist'>  
        <Reviewlist style={{ marginTop: "100px" }} /> 
      </div>

      <div className="App">
        <Navbar />
      </div>
    </div>
  )
}

export default Cart;
