import React, { useState } from 'react';
import {  useNavigate} from 'react-router-dom';
import './Admin.css'; 

function Login() {
  // State to manage the input values and authentication status
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  // Hardcoded username and password
  const hardcodedUsername = 'admin';
  const hardcodedPassword = 'password';

  const handleLogin = (e) => {
    e.preventDefault();

    // Check if the entered credentials match the hardcoded values
    if (username === hardcodedUsername && password === hardcodedPassword) {
      setAuthenticated(true);
    } else {
      alert('Invalid username or password');
    }
  };
  const navigate = useNavigate();
  

  return (
    <div className="login-container10">
      {authenticated ? (
        // If authenticated, redirect to the Image page
        navigate("/Image", { state: { successMessage: 'success' } })      
        ) : (
        <form onSubmit={handleLogin} className="login-form10">
          <h1 style={{fontSize:'40px'}}>Login</h1>
          <div className="form-group10">
            <label id='label1' htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group10">
            <label id='label2' htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className='' type="submit">Login</button>
        </form>
      )}
    </div>
  );
}

export default Login;
