import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import Navbar from '../components/Navbar';
import ImageModal from './Imagemodal';
import { useLocation, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import Select from 'react-select';
import '@fortawesome/fontawesome-free/css/all.css';
import { LRUCache } from 'lru-cache';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const cache = new LRUCache({ max: 500 });
const fetchData = async (url) => {
    if (cache.has(url)) {
        return cache.get(url);
    }

    const response = await fetch(url);
    const data = await response.json();

    cache.set(url, data);
    return data;
};

const HomePage = (props) => {
    const [activeCategory, setActiveCategory] = useState('all');
    const [showCategories, setShowCategories] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [quantity, setQuantities] = useState({});
    const [isCategoryClicked, setIsCategoryClicked] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [extraImages, setExtraImages] = useState([]);
    const [itemCounts, setItemCounts] = useState({});
    const [filteredItems, setFilteredItems] = useState([]);
    const [matchingCategory, setMatchingCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingItems, setIsLoadingItems] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading1, setIsLoading1] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showToast, setShowToast] = useState(false);


    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiLocalUrl = process.env.REACT_APP_API_LOCAL_URL;



    const newArrivalIndex = categories.findIndex(category => category.name === 'NEW ARRIVAL');

    if (newArrivalIndex !== -1) {
        const newArrivalCategory = categories.splice(newArrivalIndex, 1)[0];
        categories.unshift(newArrivalCategory);
    }

    const otherAccessoriesIndex = categories.findIndex(category => category.name === 'OTHER ACCESSORIES');

    if (otherAccessoriesIndex !== -1) {
        const otherAccessoriesCategory = categories.splice(otherAccessoriesIndex, 1)[0];
        categories.push(otherAccessoriesCategory);
    }

    const Ledcode = sessionStorage.getItem('Ledcode');

    if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {
        // Return back from the page or perform any desired action
        window.history.back();
    }


    const customerType = sessionStorage.getItem('CustomerType');


    const handlePopstate = () => {
        // Push another state to the history stack, effectively preventing the user from going back
        window.history.pushState(null, null, window.location.pathname);
    };

    const placeholderImageUrl = 'https://i.postimg.cc/0j3ppK0d/Cart-PNG-Clipart.png';


    useEffect(() => {
        if (customerType === 'CUSTOMER' || customerType === 'Customer') {
            // Push a new state to the history stack when the component mounts
            window.history.pushState(null, null, window.location.pathname);

            // Add a listener to the 'popstate' event
            window.addEventListener('popstate', handlePopstate);

            // Clean up the listener when the component unmounts
            return () => {
                window.removeEventListener('popstate', handlePopstate);
            };
        }
    }, [customerType]);

    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Load image URL when the item is in the viewport
                        const item = entry.target;
                        const itemName = item.getAttribute('data-item-name');
                        loadImageUrl(itemName);
                        observer.current.unobserve(item);
                    }
                });
            },
            { threshold: 0.5 } // Adjust the threshold as needed
        )
    );


    // Define a function to load the image URL for an item
    const loadImageUrl = async (itemName) => {
        try {
            // Fetch the image URL for the given item name
            const imageUrl = await fetchImageUrl(itemName);

            // Update the state with the fetched image URL
            setItems((prevItems) =>
                prevItems.map((item) =>
                    item.name === itemName ? { ...item, imageUrl } : item
                )
            );
        } catch (error) {
            console.error(`Error loading image URL for ${itemName}:`, error);
        }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            const categoriesData = await fetchData(`${apiBaseUrl}/getCategories/JAPAN_ACCESORIES`);

            const mappedCategories = categoriesData.map(category => ({ id: category.Id, name: category.Name }));
            setCategories(mappedCategories);
        };

        fetchCategories();
    }, []);

    const handleDropdownChange = (selectedOption, item) => {
        const selectedValue = selectedOption ? selectedOption.value : '';
        setSelectedValue(selectedValue); // Update selectedValue state
        // Update the selected value for the specific item
        setItems(prevItems => {
            return prevItems.map(prevItem => {
                if (prevItem.ID === item.ID) {
                    return {
                        ...prevItem,
                        selectedValue: selectedValue // Save the selected value for this item
                    };
                } else {
                    return prevItem;
                }
            });
        });
        handleSaveButtonClick(
            item.ID,
            item.name,
            quantity,
            item.MRP,
            item.DP,
            item.AP,
            item.MOP,
            item.imageUrl,
            item.Qty,
            item.Branch,
            item.RealPrateTax,
            selectedValue
        );
    };

    const clearSelectedValue = (itemId) => {
        setSelectedValue('');

        // Update the selected value for the specific item in the items state
        setItems(prevItems => {
            return prevItems.map(prevItem => {
                if (prevItem.ID === itemId) {
                    return {
                        ...prevItem,
                        selectedValue: '' // Clear the selectedValue for this item
                    };
                } else {
                    return prevItem;
                }
            });
        });
    };


    useEffect(() => {
        const fetchItems = async () => {
            if (activeCategory) {
                const selectedCategory = categories.find((category) => category.name === activeCategory);
                if (selectedCategory) {
                    const cacheKey = `items_${selectedCategory.id}`; // Unique cache key for the category

                    if (cache.has(cacheKey)) {
                        const cachedItems = cache.get(cacheKey);
                        setItems(cachedItems);
                        setItemCounts((prevCounts) => ({
                            ...prevCounts,
                            [selectedCategory.name]: cachedItems.length,
                        }));
                    } else {
                        setIsLoadingItems(true);

                        try {
                            const endpoint = `${apiBaseUrl}/getProductByCategory/JAPAN_ACCESORIES?Id=${selectedCategory.id}`;
                            const itemsData = await fetchData(endpoint);

                            const items = await Promise.all(
                                itemsData.map(async (item) => {
                                    try {
                                        const dropdownURL = `${apiBaseUrl}/getProductByNewFilter/JAPAN_ACCESORIES?filter=${encodeURIComponent(item.ItemName)}`;
                                        const dropdownResponse = await fetch(dropdownURL);
                                        if (!dropdownResponse.ok) {
                                            throw new Error('Network response was not ok');
                                        }
                                        const dropdownData = await dropdownResponse.json();
                                        const dropdownOptions = dropdownData.map(option => option.model);
                                        return {
                                            ID: item.ID,
                                            name: item.ItemName,
                                            MRP: item.MRP,
                                            MOP: item.MOP,
                                            DP: item.DP,
                                            AP: item.AP,
                                            Branch: item.Branch,
                                            RealPrateTax: item.RealPrateTax,
                                            Qty: item.Qty,
                                            imageUrl: `${apiLocalUrl}/uploads/${item.ID}.jpg?v=${Date.now()}`,
                                            dropdownOptions: dropdownOptions,
                                            category: selectedCategory.name,
                                        }
                                    } catch (error) {
                                        console.error('Error fetching dropdown options:', error);
                                        return {  // Return item with empty dropdown options if fetch fails
                                            ID: item.ID,
                                            name: item.ItemName,
                                            MRP: item.MRP,
                                            MOP: item.MOP,
                                            DP: item.DP,
                                            AP: item.AP,
                                            Branch: item.Branch,
                                            RealPrateTax: item.RealPrateTax,
                                            Qty: item.Qty,
                                            imageUrl: `${apiLocalUrl}/uploads/${item.ID}.jpg?v=${Date.now()}`,
                                            dropdownOptions: [],
                                            category: selectedCategory.name,
                                        };
                                    }
                                })
                            );

                            setItems(items);
                            setIsImageLoaded(true);

                            items.forEach((item) => {
                                const itemElement = document.querySelector(`[data-item-name="${item.name}"]`);
                                if (itemElement) {
                                    observer.current.observe(itemElement);
                                }
                            });
                            cache.set(cacheKey, items);

                            setItemCounts((prevCounts) => ({
                                ...prevCounts,
                                [selectedCategory.name]: items.length,
                            }));
                        } catch (error) {
                            console.error('Error fetching items:', error);
                        }

                        setIsLoadingItems(false);
                    }
                }
            }
        };

        fetchItems();
    }, [activeCategory, categories]);



    const fetchImageUrl = async (ItemName) => {
        try {
            const encodedItemName = encodeURIComponent(ItemName);
            const imageUrlEndpoint = `${apiBaseUrl}/stock/getProductImages/JAPAN_ACCESORIES?itemname=${encodedItemName}`;
            const response = await fetch(imageUrlEndpoint);
            const imageData = await response.json();

            if (Array.isArray(imageData) && imageData.length > 0) {
                const photoData = imageData[0]?.photo?.data;

                if (photoData) {
                    const base64String = arrayBufferToBase64(photoData);
                    return `data:image/jpeg;base64,${base64String}`;
                }
            }

            return 'https://example.com/default-image.jpg';
        } catch (error) {
            console.error('Error fetching image:', error);
            return 'https://example.com/default-image.jpg';
        }
    };

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;

        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    };

    const handleCategoryClick = (category) => {
        if (activeCategory === category && !searchTerm) {
            setActiveCategory('all');
        } else {
            setActiveCategory(category);
            if (searchTerm && category.includes(searchTerm)) {
                setSearchTerm('');
                setShowCategories(true);
                setIsCategoryClicked(true);
            }
        }
    };

    const handleSearchTermChange = (e) => {

        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        let filteredItems = items.filter((item) => {
            return activeCategory === 'all' || item.category === activeCategory;
        });

        // apply any other filters, e.g. search term
        filteredItems = filteredItems.filter((item) => {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase());
        });

        // check if filtered items contain a category that matches the search term
        if (searchTerm !== '') {
            const foundCategory = categories.find(
                (category) => category.name.toLowerCase() === searchTerm.toLowerCase()
            );
            // setMatchingCategory(foundCategory);
        } else {
            setMatchingCategory(null);
        }

        if (matchingCategory) {
            // Open the category if it matches the search term
            setShowCategories(true);
            setActiveCategory(matchingCategory.name);
        }

        setFilteredItems(filteredItems);
    }, [activeCategory, searchTerm, items, categories, matchingCategory]);

    const handleImageClick = (imageUrl) => {
        setIsLoading1(true);
        // Find the selected item based on its image URL
        const selectedItem = items.find(item => item.imageUrl === imageUrl);

        // If no item is selected, return
        if (!selectedItem) {
            return;
        }

        // Make a GET request to the extra image API using the selected item's name/id
        fetch(`${apiBaseUrl}/stock/getImages/JAPAN_ACCESORIES?itemname=${encodeURIComponent(selectedItem.name)}&itemid=${selectedItem.ID}`)
            .then(response => response.json())
            .then(data => {
                // Decode the images and update the extraImages state with the retrieved data
                const decodedImages = data.map(image => {
                    const base64String = btoa(
                        new Uint8Array(image.Image.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
                    );
                    const imageUrl = `data:${image.Image.contentType};base64,${base64String}`;
                    const altText = image.alt; // Retrieve the alt attribute from the API response
                    return { imageUrl, altText }; // Return an object with both imageUrl and altText
                });

                setExtraImages(decodedImages);
                setSelectedImage(imageUrl);
                // console.log('Extra images retrieved from API:', decodedImages);
                setIsLoading1(false);
                setIsModalOpen(true);
            })
            .catch(error => {
                console.error('Error fetching extra images:', error);
                setIsLoading1(false);
            });
    };

    const handleQuantityChange = (id, e) => {
        const value = e.target.value;

        setQuantities((prevQuantities) => {
            return {
                ...prevQuantities,
                [id]: value,
            };
        });
    };

    const handleSaveButtonClick = (ID, itemName, quantity, MRP, MOP, DP, AP, imageSrc, Qty, Branch, RealPrateTax, selectedValue) => {
        // console.log("MOP:", MOP);
        // console.log("DP:", DP);
        // console.log("AP:", AP);

        if (quantity === null || quantity === "" || (typeof quantity === 'object' && (quantity.undefined === "" || quantity.undefined === "0"))) {
            return;
        }
        if (quantity === null || quantity === "" || (typeof quantity === 'object' && Object.keys(quantity).length === 0)) {
            return;
        }
        if (quantity === null || quantity === "" || (typeof quantity === 'object' && (quantity.undefined === "" || quantity.undefined.startsWith("-") || isNaN(quantity.undefined)))) {
            return;
        }
        if (quantity === null || quantity === "" || (typeof quantity === 'object' && Object.keys(quantity).length === 0)) {
            return;
        }


        if (quantity <= 0) {
            return;
        }

        // console.log(Qty);

        if (quantity > Qty) {
            alert('Out of stock');
            return;
        }


        const existingItemIndex = selectedItems.findIndex(item => item.selectedValue === selectedValue && item.ID === ID);
        if (existingItemIndex !== -1) {
            const updatedSelectedItems = [...selectedItems];
            updatedSelectedItems[existingItemIndex].quantity = quantity; // Update the existing item quantity with the new value
            setSelectedItems(updatedSelectedItems);

            if (quantity === 0) {
                // Remove the item from selectedItems and local storage if the new quantity is zero
                updatedSelectedItems.splice(existingItemIndex, 1);
                setSelectedItems(updatedSelectedItems);

                const existingProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];
                const updatedProducts = existingProducts.filter(item => item.ID !== ID);
                localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
            }

        } else {
            const newSelectedItem = { ID: ID, name: itemName, quantity: quantity, MRP: MRP, AP: DP, imageSrc: imageSrc, Qty: Qty, DP: MOP, MOP: AP, Branch: Branch, RealPrateTax: RealPrateTax, selectedValue: selectedValue };
            setSelectedItems(prevSelectedItems => [...prevSelectedItems, newSelectedItem]);

            if (quantity === 0) {
                return; // Don't save to local storage if the quantity is zero
            }

            const existingProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];
            const existingProductIndex = existingProducts.findIndex(item => item.selectedValue === selectedValue && item.ID === ID);
            if (existingProductIndex !== -1) {
                const updatedProducts = [...existingProducts];
                updatedProducts[existingProductIndex].quantity = quantity; // Update the existing product quantity with the new value
                localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
            } else {
                const newProduct = { ID: ID, name: itemName, quantity: quantity, MRP: MRP, AP: DP, imageSrc: imageSrc, Qty: Qty, DP: MOP, MOP: AP, Branch: Branch, RealPrateTax: RealPrateTax, selectedValue: selectedValue };
                const updatedProducts = [...existingProducts, newProduct];
                localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));
                // console.log(updatedProducts);
            }
        }

        // Call clearSelectedValue with item ID
        clearSelectedValue(ID);


        // Show toast notification
        setShowToast(true);

        // // show notification
        toast.success('Item added to cart!', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const handleDownloadPDF = async () => {
        const doc = new jsPDF();
        const pageHeight = doc.internal.pageSize.getHeight();
        const imgWidth = 15; // Desired width of the image
        let y = 10;

        const selectedCategory = categories.find(category => category.name === activeCategory);

        if (selectedCategory) {
            doc.addFont('path/to/custom-font.ttf', 'CustomFont', 'normal'); // Register custom font

            doc.setFillColor(255, 255, 255); // Set background color to white
            doc.rect(0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), 'F');
            doc.setFont('Helvetica', 'bold');
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 255);
            doc.text(`Category:`, 10, y);
            doc.setTextColor(0, 0, 0);
            doc.text(selectedCategory.name, 40, y);

            y += 15; // Add 15 units of space below the category name

            let currentPage = 1;

            const handleImageLoad = (item, img) => {
                return new Promise((resolve) => {
                    const imgHeight = (imgWidth / img.width) * img.height; // Maintain the aspect ratio

                    if (y + imgHeight + 30 > pageHeight) {
                        // Item doesn't fit on the current page, add a new page
                        doc.addPage();
                        y = 10;
                        currentPage++;
                    }

                    doc.setFont('CustomFont'); // Set custom font
                    doc.setFontSize(12);

                    doc.setDrawColor(0, 0, 0); // Set line color to black
                    doc.setLineWidth(0.5);
                    doc.line(10, y + imgHeight + 15, doc.internal.pageSize.getWidth() - 10, y + imgHeight + 15); // Add line separator

                    doc.addImage(img, 'JPEG', 10, y, imgWidth, imgHeight, '', 'FAST', 0, { borderWidth: 1, borderColor: 'black' });

                    doc.setTextColor(255, 0, 0); // Set text color to red
                    doc.text(item.name, 60, y + imgHeight / 2);

                    if (customerType === 'AGENT') {
                        doc.text(`AP: ${item.AP}  MOP: ${item.MOP}  MRP: ${item.MRP}    `, 60, y + imgHeight / 2 + 10);
                    } else if (customerType === 'DEALER') {
                        doc.text(`DP: ${item.DP}  MOP: ${item.MOP} MRP: ${item.MRP}  `, 60, y + imgHeight / 2 + 10);
                    }
                    else if (customerType === 'JS_EMPLOYEE') {
                        doc.text(`Branch: ${item.Branch} MRP: ${item.MRP}  `, 60, y + imgHeight / 2 + 10);
                    }
                    else if (customerType === 'TK_EMPLOYEE') {
                        doc.text(`Branch: ${item.Branch} MRP: ${item.MRP}  `, 60, y + imgHeight / 2 + 10);
                    }
                    else if (customerType === 'JS_MANAGER') {
                        doc.text(`RealPrateTax: ${item.RealPrateTax} MRP: ${item.MRP}  `, 60, y + imgHeight / 2 + 10);
                    }
                    else if (customerType === 'TK_MANAGER') {
                        doc.text(`RealPrateTax: ${item.RealPrateTax} MRP: ${item.MRP}  `, 60, y + imgHeight / 2 + 10);
                    }
                    else {
                        doc.text(`MOP: ${item.MOP}  MRP: ${item.MRP}  `, 60, y + imgHeight / 2 + 10);
                    }

                    y += imgHeight + 20; // Add 20 units of space below the image and text

                    if (currentPage === 1 && y >= pageHeight - 20) {
                        // First page is full, move to the next page
                        doc.addPage();
                        y = 10;
                        currentPage++;
                    }

                    resolve();
                });
            };

            for (const item of filteredItems) {
                if (item.imageUrl) {
                    const img = new Image();
                    img.crossOrigin = 'Anonymous';
                    img.src = item.imageUrl;

                    await new Promise((resolve) => {
                        img.onload = () => {
                            resolve(handleImageLoad(item, img));
                        };
                    });
                }
            }

            // Save and download the PDF
            doc.save(`${activeCategory}_items.pdf`);
        }
    };

    const navigate = useNavigate();

    const handleSearchButtonClick = () => {
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        sessionStorage.setItem('searchofTerm', encodedSearchTerm);
        navigate('/Search');
    };

    return (
        <div className='app1'>
            <div className="homepage-container">

                <div className="search-container">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        placeholder="Search items..."
                        className="search-input"
                    />
                    <button className="searchbut" onClick={handleSearchButtonClick}>
                        <i style={{ marginTop: '3px' }} className="fa fa-search" aria-hidden="true"></i>
                    </button>
                </div>
                {showToast && ( // Render ToastContainer conditionally
                    <ToastContainer
                        position="top-right"
                        autoClose={100} // Auto close after 3 seconds
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                )}
                <div className="home-container" >

                    <img src="https://i.postimg.cc/FFc0w6KZ/ALIK-LOGO.png" alt="Example" className="example-image" />
                    <h2 id='home1'>Order</h2>
                </div>

                <div className="sample-container">
                    <div className="categories-container">
                        {(showCategories || isCategoryClicked) && (
                            <div className="category-browse">
                                <ul>
                                    {categories.map((category) => (
                                        <li
                                            key={category.id}
                                            className={`category ${activeCategory === category.name ? 'active' : ''}`}
                                            onClick={() => handleCategoryClick(category.name)}
                                        >
                                            {category.name}


                                            <div className="category-count">
                                                <button onClick={handleDownloadPDF}>

                                                    <img className="download-icon" src="https://i.postimg.cc/cJB075yf/cloud-2.png" alt="Download" />

                                                </button>

                                          </div>
                                        </li>

                                    ))}
                                </ul>
                            </div>
                        )}
                        {!showCategories && !isCategoryClicked && (
                            <>
                                {categories.map((category) => (
                                    <div
                                        key={category.id}
                                        className={`category ${activeCategory === category.name ? 'active' : ''}`}
                                        onClick={() => handleCategoryClick(category.name)}
                                    >
                                        <div className="category-name">
                                            {category.name}

                                        </div>

                                        <div className="category-count">
                                            <button onClick={handleDownloadPDF}>
                                                <img className="download-icon" src="https://i.postimg.cc/cJB075yf/cloud-2.png" alt="Download" />
                                            </button>
                                        </div>

                                        {activeCategory === category.name && (

                                            <div onClick={(e) => e.stopPropagation()} className="items-container">
                                                {isLoading ? (
                                                    <div className="loading-animation">
                                                        {/* Insert your loading animation component here */}
                                                        <div className="loader"></div>
                                                        <p>please wait<span className="blink-animation">...</span></p>
                                                    </div>
                                                ) : (
                                                    filteredItems.map((item) => (
                                                        <div key={item.ID} className="item">
                                                            <div className="image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <img
                                                                    src={item.imageUrl}
                                                                    alt={item.name}
                                                                    className="item-image"
                                                                    width={150}
                                                                    height={150}
                                                                    onClick={() => handleImageClick(item.imageUrl)}

                                                                />
                                                                {item.dropdownOptions.length > 0 && (
                                                                    <div className='reactSelectDiv'>
                                                                        <Select
                                                                            options={item.dropdownOptions.map(option => ({ value: option, label: option }))}
                                                                            value={item.selectedValue ? { value: item.selectedValue, label: item.selectedValue } : null}
                                                                            onChange={(selectedOption) => handleDropdownChange(selectedOption, item)}
                                                                            placeholder="Select compatible"
                                                                            defaultValue={{ value: '', label: 'Select compatible model' }}
                                                                            className="custom-react-select"
                                                                            classNamePrefix='reactSelectDiv2'
                                                                            styles={{
                                                                                control: (provided, state) => ({
                                                                                    ...provided,
                                                                                    fontSize: '14px',
                                                                                    textAlign: 'left',
                                                                                    width: 'auto', // Default width
                                                                                    '@media (max-width: 768px)': {
                                                                                        width: '100%' // Full width on mobile devices
                                                                                    }
                                                                                }),
                                                                                dropdownIndicator: (provided) => ({
                                                                                    ...provided,
                                                                                    fontSize: '14px',
                                                                                }),
                                                                                option: (provided) => ({
                                                                                    ...provided,
                                                                                    fontSize: '14px',
                                                                                    textAlign: 'left'
                                                                                }),
                                                                            }}
                                                                        />

                                                                    </div>
                                                                )}

                                                            </div>

                                                            <span id="h1">
                                                                <span id='h9'>
                                                                    {item.name} <br />
                                                                </span>
                                                                <div className='yoo1'>

                                                                    <span id="h2">
                                                                        {customerType === 'AGENT' && (
                                                                            <span>
                                                                                <span id="h3">AP:{item.AP}{' '}</span>
                                                                            </span>
                                                                        )}

                                                                        {customerType === 'DEALER' && (
                                                                            <span>
                                                                                <span id="h3">DP:{item.DP}{' '}</span>
                                                                            </span>
                                                                        )}

                                                                                                                                                                        

                                                                        {(customerType === 'JS_EMPLOYEE' || customerType === 'TK_EMPLOYEE') && (
                                                                            <span>
                                                                                <span id="h3">Branch:{item.Branch}{' '}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <span id="h3">MRP: {item.MRP}{' '}</span>

                                                                            </span>
                                                                        )}


                                                                        {(customerType === 'JS_MANAGER' || customerType === 'TK_MANAGER') && (
                                                                            <span>
                                                                                <span id="h3">Rate:{item.RealPrateTax}{' '}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <span id="h3">MRP: {item.MRP}{' '}</span>

                                                                            </span>
                                                                        )}

                                                                        {(customerType !== 'JS_EMPLOYEE' && customerType !== 'JS_MANAGER' && customerType !== 'TK_MANAGER' && customerType !== 'TK_EMPLOYEE') && (
                                                                            <span>
                                                                                <span id="h3">MOP:{item.MOP}{' '}</span>
                                                                                <span id="h3">MRP: {item.MRP}{' '}</span>
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </span>

                                                            {customerType !== 'AGENT' && (
                                                                <input
                                                                    type="number"
                                                                    min={1}
                                                                    placeholder=""
                                                                    className="item-quantity"
                                                                    inputMode="numeric"
                                                                    onKeyDown={(event) => {
                                                                        if (event.key === 'Enter') {
                                                                            event.target.blur(); // Trigger onBlur event when Enter key is pressed
                                                                            event.target.value = ''; // Clear the input field after handling
                                                                        }
                                                                    }}
                                                                    onKeyPress={(event) => {
                                                                        // Check for mobile "Done" key press
                                                                        if (event.key === 'Enter' || event.key === 'Done') {
                                                                            event.target.blur(); // Trigger onBlur event
                                                                            event.target.value = ''; // Clear the input field after handling
                                                                        }
                                                                    }}
                                                                    onBlur={(event) => {

                                                                        handleQuantityChange(item.id, { target: { value: '' } });
                                                                        // console.log('Checking quantity:', quantity[item.id], 'Qty:', item.Qty);
                                                                        if (quantity[item.id] > item.Qty) {
                                                                            const modalOverlay = document.createElement('div');
                                                                            modalOverlay.style.position = 'fixed';
                                                                            modalOverlay.style.top = '0';
                                                                            modalOverlay.style.left = '0';
                                                                            modalOverlay.style.width = '100%';
                                                                            modalOverlay.style.height = '100%';
                                                                            modalOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                                                                            modalOverlay.style.zIndex = '9999';
                                                                            document.body.appendChild(modalOverlay);

                                                                            const alertBox = document.createElement('div');
                                                                            alertBox.innerHTML = 'Available stock is ' + item.Qty + ' Nos';
                                                                            alertBox.style.position = 'fixed';
                                                                            alertBox.style.top = '50%';
                                                                            alertBox.style.left = '50%';
                                                                            alertBox.style.transform = 'translate(-50%, -50%)';
                                                                            alertBox.style.backgroundColor = '#fff';
                                                                            alertBox.style.padding = '20px';
                                                                            alertBox.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.5)';
                                                                            alertBox.style.zIndex = '10000';
                                                                            modalOverlay.appendChild(alertBox);

                                                                            const inputId = `input-${item.id}`; // use item ID as the input ID
                                                                            setTimeout(() => {
                                                                                const inputElement = document.getElementById(inputId);
                                                                                if (inputElement) {
                                                                                    inputElement.focus(); // return focus to the input element
                                                                                }
                                                                                modalOverlay.remove(); // remove the modal overlay and alert box
                                                                            }, 700); // delay removal for 3 seconds
                                                                            return;
                                                                        }
                                                                        handleSaveButtonClick(item.ID, item.name, quantity, item.MRP, item.DP, item.AP, item.MOP, item.Qty, item.imageSrc, item.Branch, item.RealPrateTax, selectedValue);
                                                                        event.target.value = '';

                                                                    }}
                                                                    onChange={(e) => handleQuantityChange(item.id, e)}

                                                                    id={`input-${item.id}`} // use item ID as the input ID
                                                                />
                                                            )}

                                                        </div>
                                                    ))
                                                )}


                                                {isLoading1 ? (
                                                    // Show the buffering loader while the data is being fetched

                                                    <div className="loading-overlay">
                                                        <div className="loading-animation2">
                                                            <div className="loader2"></div>
                                                        </div>
                                                    </div>

                                                ) : isModalOpen && selectedImage && extraImages ? (
                                                    // Show the ImageModal when isModalOpen is true and the data is available
                                                    <ImageModal
                                                        selectedImage={selectedImage}
                                                        extraImages={extraImages}
                                                        onCloseModal={() => {
                                                            setIsModalOpen(false); // Close the ImageModal when onCloseModal is called
                                                            setSelectedImage(null); // Reset selectedImage when the modal is closed
                                                        }}
                                                    />
                                                ) : null}

                                            </div>
                                        )}
                                    </div>
                                ))}

                            </>

                        )}

                        <div className="App">
                            <Navbar />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(HomePage);
