import React, { useState, useRef, useEffect } from 'react';
import './Imagemodal.css';

const ImageModal = ({ selectedImage, onCloseModal, extraImages }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchStartPosition, setTouchStartPosition] = useState(null);
  const slideContainerRef = useRef(null);
  const [showSwipeText, setShowSwipeText] = useState(true);
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint according to your needs

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % extraImages.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? extraImages.length - 1 : prevIndex - 1
    );
  };

  const handleTouchStart = (event) => {
    setTouchStartPosition(event.touches[0].clientX);
    setShowSwipeText(false);
  };

  const Ledcode = sessionStorage.getItem('Ledcode');
// console.log("Ledcode:", Ledcode);

if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {
  // Return back from the page or perform any desired action
  window.history.back();
}

useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === "ArrowRight") {
      handleNextImage();
    } else if (event.key === "ArrowLeft") {
      handlePrevImage();
    }
  };

  document.addEventListener("keydown", handleKeyDown);

  return () => {
    document.removeEventListener("keydown", handleKeyDown);
  };
}, [handleNextImage, handlePrevImage]);

const handleTouchEnd = (event) => {
    const touchEndPosition = event.changedTouches[0].clientX;
    const touchDistance = touchEndPosition - touchStartPosition;
    const slideContainerWidth = slideContainerRef.current.offsetWidth;

    if (isExtraImageAvailable) {
      if (touchDistance > slideContainerWidth / 4) {
        handlePrevImage();
      } else if (touchDistance < -(slideContainerWidth / 4)) {
        handleNextImage();
      }
    }
  };

  const isExtraImageAvailable = extraImages.length > 0;
  const currentExtraImage = isExtraImageAvailable
    ? extraImages[currentImageIndex - 1]
    : null;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSwipeText(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="image-modal">
      <div className="modal-overlay" onClick={onCloseModal}></div>
      <div className="modal-content">
        <div className="close-btn">
          <button
            style={{
              backgroundColor: 'red',
              color: 'white',
              borderRadius: '3px',
              border:'white'
            }}
            onClick={onCloseModal}
          >
            x
          </button>
        </div>
        <div
          className="slide-container"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          ref={slideContainerRef}
        >
          {currentImageIndex === 0 ? (
            <img src={selectedImage} alt="" className="slide-image" />
          ) : (
            isExtraImageAvailable && (
              <img
                src={currentExtraImage.imageUrl}
                alt={currentExtraImage.altText}
                className="slide-image"
              />
            )
          )}
        </div>
        {isExtraImageAvailable && showSwipeText && isMobile && (
          <div className="swipe-text">Swipe to see more images</div>
        )}
        {isExtraImageAvailable && !isMobile && (
          <div className="scroll-icon-container">
            {currentImageIndex !== 0 && (
              <div className="scroll-icon prev" onClick={handlePrevImage}>
                {'<'}
              </div>
            )}      
            {currentImageIndex !== extraImages.length - 1 && (
              <div className="scroll-icon next" onClick={handleNextImage}>
                {'>'}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageModal;
