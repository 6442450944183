import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import './Success.css';

function Success() {

  const location = useLocation();
  const { addedSum } = location.state || {};

  const currentDateTime = new Date();

  const navigate = useNavigate();
  
  const handleReturnHome = () => {
    navigate('/Home', { replace: true });
  };

  const formattedDate = currentDateTime.toLocaleDateString([], {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const formattedTime = currentDateTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  const Ledcode = sessionStorage.getItem('Ledcode');
  // console.log("Ledcode:", Ledcode);
  

  if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {
    // Return back from the page or perform any desired action
    window.history.back();
  }

return (
    <div className="success-container">
      <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
      <h1 className="success-title1">Hurray!</h1>
      <h1 className="success-title">Order Placed Successfully!</h1>
      {addedSum !== undefined ? (
        <p className="success-addedSum">Total Amount: {addedSum.toFixed(2)}</p>
      ) : (
        <p className="error-message">Error: Total amount not available.</p>
      )}
      <p className="success-date">Date: {formattedDate}</p>
      <p className="success-time">Time: {formattedTime}</p>
      <button className="return-home-button" onClick={handleReturnHome}>
        Return Home
      </button>
    </div>
  );
}

export default Success;
