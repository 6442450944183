import React, { useState, useEffect } from "react";
import './Login.css';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import Main from "./Main";
import {  faUser } from '@fortawesome/free-solid-svg-icons';


function Login() {
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({});
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


  useEffect(() => {
    // Check if the user is already logged in
    const isLoggedIn = Cookies.get("isLoggedIn");
    if (isLoggedIn) {
      navigate("/Home");
    }
  }, [navigate]);

  useEffect(() => {
    // Push a new state to the history stack when the component mounts
    window.history.pushState(null, null, window.location.pathname);

    // Add a listener to the 'popstate' event
    window.addEventListener('popstate', handlePopstate);

    // Clean up the listener when the component unmounts          
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handlePopstate = () => {
    // Push another state to the history stack, effectively preventing the user from going back
    window.history.pushState(null, null, window.location.pathname);
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const username = event.target.uname.value;
    const password = event.target.pass.value;

    try {
      // Make an API call to authenticate the user
      const response = await fetch(
        `${apiBaseUrl}/user/SignIn/JAPAN_ACCESORIES?username=${username}&password=${password}`
      );

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          const user = data[0];

          // Check if the user active is 0
          if (user.Active === 0) {
            // Redirect to the OTP page or handle it as required
            navigate("/Otp", {
              state: { username, password },
            });

            return;
          }

          // Store user information in cookies if "Keep me signed in" checkbox is checked
          if (keepSignedIn) {
            Cookies.set("isLoggedIn", true);
            Cookies.set("username", user.username);
            Cookies.set("customerType", user.CustomerType);
            Cookies.set("ledCode", user.Ledcode);
            Cookies.set("ledName", user.LedName);
            Cookies.set("AgentCode", user.AgentCode);
          }

          setData(user);
          setErrorMessages({});
          navigate(
            user.CustomerType === "AGENT" || user.CustomerType === "DEALER" || user.CustomerType === "JS_MANAGER"  || user.CustomerType === "JS_EMPLOYEE" || user.CustomerType === "TK_MANAGER" || user.CustomerType === "TK_EMPLOYEE" ? "/Main" : "/Home",
            {
              state: { isGuest: false, Ledcode: user.Ledcode, customer: user.CustomerType, AgentCode: user.AgentCode },
            }
          );

          sessionStorage.setItem('Ledcode', user.Ledcode);
          sessionStorage.setItem('LedName', user.LedName);
          sessionStorage.setItem('CustomerType', user.CustomerType);
          sessionStorage.setItem('AgentCode', user.AgentCode);

        } else {
          setErrorMessages({ name: "uname", message: "Invalid username or password" });
        }
      } else {
        setErrorMessages({
          name: "uname",
          message: "Invalid username or password",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessages({ name: "uname", message: "An error occurred" });
    }
  };

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label id="username">Username</label>
          <input type="text" name="uname" required />
          {renderErrorMessage("uname")}
        </div>
        <div className="input-container" >
          <label id="password">Password</label>
          <input type={showPassword ? "text" : "password"} name="pass" required />
          <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
            {showPassword ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </span>
          {renderErrorMessage("pass")}
        </div>

        <div className="button-container">
          <input type="submit" value="Submit" />
        </div>

        <div className="alternativeLogin">
          <label id="label" style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
            <input type="checkbox" style={{ marginRight: '5px', verticalAlign: 'middle' }} onChange={(e) => setKeepSignedIn(e.target.checked)} />
            <span style={{ whiteSpace: 'nowrap', marginRight: '70px' }}>Keep me signed in</span>
          </label>
          <div style={{ marginTop: '10px', marginLeft: '20px' }}>
            Don't have an account?{' '}
            <Link
              to='/Signup'
              className="signup"
              type="button"
            >
              Sign up
            </Link>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div className="app">
      {/* <Link to="/Admin" >
      <FontAwesomeIcon icon={faUser} className="navbar-icon" />     
       </Link> */}
      <div className="login-form">
        <div className="title">
          <div id="header">
            <img src="https://i.postimg.cc/FFc0w6KZ/ALIK-LOGO.png" alt="Logo" style={{ width: '100px', height: '100px', marginLeft: '70px', marginTop: '-20px' }} />
          </div>
         
        </div>
        {isSubmitted ? <div >User is successfully logged in</div> : renderForm}
      </div>
    </div>
  );
}

export default React.memo(Login);     
